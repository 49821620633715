<template>
  <div id="chart-container">
    <canvas id="chart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "MixedChart",
  el: "#chart-container",

  methods: {
    renderizarGrafico() {
      let ctx = document.getElementById("chart");
      new Chart(ctx, this.dadosGrafico);
      ctx.style.height = "500px";
    },
    limparCanvasTemplate() {
      let container = document.getElementById("chart-container");
      container.innerHTML = '<canvas id="chart"></canvas>';
    },
  },

  data() {
    return {};
  },

  mounted() {},

  watch: {
    dadosGrafico() {
      this.limparCanvasTemplate();
      this.renderizarGrafico();
    },
  },

  props: {
    dadosGrafico: { type: Object, required: false, default: {} },
  },
};
</script>
