<template>
  <v-bottom-sheet v-model="dialog" width="500px">
    <template #activator="{ on: onMenu }">
      <v-tooltip top>
        <template #activator="{ on: onTooltip }">
          <v-btn
            fab
            right
            bottom
            fixed
            color="primary"
            class="mr-16"
            dark
            v-on="{ ...onMenu, ...onTooltip }"
          >
            <v-icon size="28" color="white">$vuetify.icons.brain</v-icon>
          </v-btn>
        </template>

        <span>Lunis.AI</span>
      </v-tooltip>
    </template>
    <v-card flat class="px-5 py-6">
      <v-row
        v-for="item in chat"
        :key="item.texto"
        class="ma-0 pa-0 my-1 mb-4 subtitle-2 white--text"
        :justify="item.tipo === 'user' ? 'end' : 'start'"
      >
        <v-col
          style="border-radius: 12px;line-height:18px"
          cols="8"
          :class="[item.tipo === 'user' ? 'primary' : 'secondary']"
        >
          {{ item.texto }}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-text-field
          dense
          :disabled="loading"
          placeholder="Pergunte algo..."
          outlined
          v-model="texto"
          hide-details
          class="mr-4"
        ></v-text-field>
        <v-btn
          :loading="loading"
          @click="perguntarLunisAiAsync"
          color="primary"
          fab
          small
        >
          <v-icon size="24" color="white">$vuetify.icons.brain</v-icon>
        </v-btn>
      </v-row>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Lunis.AI",
  props: {},
  methods: {
    ...mapActions("analises", ["perguntarLunisAi"]),
    perguntarLunisAiAsync: async function () {
      const texto = this.texto;
      this.texto = null;
      this.chat.push({ tipo: "user", texto });
      this.chat.push({
        tipo: "assistant",
        texto: "Aguarde, estou processando sua pergunta...",
      });
      this.loading = true;
      try {
        let resposta = await this.perguntarLunisAi({
          idAnalise: this.$route.query["id"],
          pergunta: texto,
        });
        this.chat.pop();
        this.chat.push({
          tipo: "assistant",
          texto: resposta,
        });
      } catch (err) {
        console.log(err);
        this.chat.pop();
        this.chat.push({
          tipo: "assistant",
          texto:
            "Ocorreu um erro com sua requisição, por favor tente novamente mais tarde ou entre em contato com nosso suporte.",
        });
      } finally {
        this.loading = false;
        this.texto = null;
      }
    },
  },
  computed: {},
  watch: {},
  data() {
    return {
      texto: "",
      dialog: false,
      loading: false,
      chat: [],
    };
  },
};
</script>
