<template>
  <v-snackbar
    v-if="alert"
    class="mx-5 mb-10"
    bottom
    v-model="isSnackbarActive"
    :timeout="3000"
    :color="alert.color"
    dismissible
  >
    {{ alert.text }}
  </v-snackbar>
</template>

<style scoped>
.actionPopupAlert {
  z-index: 1000;
  position: absolute;
  opacity: 0.8;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations(["unsetAlert"]),
  },
  computed: {
    ...mapGetters(["alert"]),
    isSnackbarActive: {
      get() {
        return !!this.alert;
      },
      set() {
        this.unsetAlert();
      },
    },
  },
  data() {
    return {};
  },
};
</script>