<template>
  <v-row class="app-content ma-0 pr-4" align="start" justify="center">
    <v-col cols="12" class="ma-0 pa-0">
      <cabecalho-padrao></cabecalho-padrao>
    </v-col>
    <v-col cols="12" class="ma-0 pa-0">
      <v-row class="ma-0 pa-0" justify="start">
        <v-col class="ma-0 pa-0" cols="6">
          <v-autocomplete
            label="Análises"
            item-value="id"
            prepend-inner-icon="mdi-database-search-outline"
            hide-details
            item-text="nome"
            :items="analises"
            v-model="idAnalise"
            :disabled="isLoadingButton"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="ma-0 pa-0 px-5 pt-3" cols="2">
          <v-btn
            outlined
            :disabled="!idAnalise"
            :loading="isLoadingButton"
            @click="deletarAnalise(idAnalise)"
            color="error"
          >
            <v-icon class="mr-2">mdi-trash-can</v-icon>
            Deletar
          </v-btn>
        </v-col>
        <v-col class="ma-0 pa-0 pt-3" cols="4">
          <v-dialog
            v-model="criacaoEdicaoAnalisesModel"
            right
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                <v-icon class="mr-2">{{
                  !!idAnalise ? "mdi-pencil" : "mdi-plus"
                }}</v-icon>
                {{ `${!!idAnalise ? "Editar" : "Adicionar"} Análise` }}
              </v-btn>
            </template>
            <v-card v-if="!isLoading && !!analise">
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="criacaoEdicaoAnalisesModel = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Crie ou Edite sua Análise</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn dark text @click="salvarEdicaoCriacaoAnaliseAsync">
                    Salvar
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-row class="ma-0 pa-0 pa-5">
                <v-col class="ma-0 pa-0" cols="6">
                  <p class="ma-0 mb-2 pa-0 v-card__text">
                    Qual o nome da análise?
                  </p>
                  <v-text-field
                    outlined
                    class="ma-0 pa-0"
                    dense
                    v-model="analise.nome"
                    placeholder="Nome"
                  ></v-text-field>
                </v-col>
                <v-col class="ma-0 pa-0 ml-4" cols="4">
                  <p class="ma-0 mb-2 pa-0 v-card__text">
                    Qual o tipo da análise?
                  </p>
                  <v-select
                    dense
                    clearable
                    outlined
                    placeholder="Selecione"
                    :items="[
                      { id: 1, nome: 'Horizontais' },
                      { id: 2, nome: 'Pareto' },
                      { id: 6, nome: 'Apresentação' },
                    ]"
                    :disabled="isLoadingButton"
                    item-text="nome"
                    item-value="id"
                    v-model="analise.tipo"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0 pa-5" v-if="analise.tipo === 6">
                <v-col cols="6">
                  <seletor-generico
                    class="mb-4"
                    v-bind:itensDisponiveis="analisesDisponiveisApresentacao"
                    nomeEntidade="Análises"
                    @changed="selecionarAnalisesApresentacao"
                    :triggerItensSelecionados="triggerAnalisesApresentacao"
                  ></seletor-generico>
                </v-col>
              </v-row>
              <v-row
                v-if="!!analise.tipo && analise.tipo !== 6"
                class="ma-0 pa-0 pa-5"
              >
                <v-col class="ma-0 pa-0 pr-2" cols="6">
                  <p class="ma-0 mb-2 pa-0 v-card__text">
                    Lista de colunas opcionais:
                  </p>
                  <v-card>
                    <v-list class="ma-0 pa-0" dense>
                      <v-list-item
                        v-for="agrupador in agrupadores"
                        :key="agrupador.nome"
                      >
                        <template>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              agrupador.nome
                            }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ agrupador.descricao }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="ma-0 pa-0 pl-2" cols="6">
                  <p class="ma-0 mb-2 pa-0 v-card__text">
                    Lista de colunas obrigatórias:
                  </p>
                  <v-card>
                    <v-list class="ma-0 pa-0" dense>
                      <template v-for="itemObrigatorio in obrigatorios">
                        <v-list-item :key="itemObrigatorio.nome">
                          <v-list-item-content>
                            <v-list-item-title>{{
                              itemObrigatorio.nome
                            }}</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ itemObrigatorio.descricao }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
              <v-divider
                v-if="!!analise.tipo && analise.tipo !== 6"
              ></v-divider>
              <v-row
                v-if="!!analise.tipo && analise.tipo !== 6"
                class="ma-0 pa-0 pa-5"
              >
                <v-col cols="6">
                  <p class="ma-0 mb-2 pa-0 v-card__text">
                    Qual o período de referência da análise?
                  </p>
                  <seletor-periodo
                    :triggerPeriodo="triggerPeriodo"
                    class="mb-0"
                    @changed="
                      (filtrosPeriodo) => {
                        analise.periodos = filtrosPeriodo;
                      }
                    "
                  ></seletor-periodo>
                  <v-checkbox
                    class="ma-0 pa-0 mt-5"
                    v-model="analise.filtrarEntrada"
                    :label="'Receita'"
                  ></v-checkbox>

                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="analise.filtrarSaida"
                    :label="'Despesa'"
                  ></v-checkbox>

                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="analise.filtrarQuitado"
                    :label="'Quitado'"
                  ></v-checkbox>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="analise.filtrarPendente"
                    :label="'Pendente'"
                  ></v-checkbox>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="analise.dataFixa"
                    :label="'Fixar data para a análise?'"
                  ></v-checkbox>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="analise.esconderPercentuais"
                    :label="'Ocultar Percentuais (%)'"
                  ></v-checkbox>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="analise.esconderTotaisColuna"
                    :label="'Ocultar Totais da Coluna'"
                  ></v-checkbox>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="analise.esconderTotaisLinha"
                    :label="'Ocultar Totais da Linha'"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <p class="ma-0 mb-2 pa-0 v-card__text">
                    Qual o agrupador de referência?
                  </p>
                  <v-select
                    dense
                    clearable
                    outlined
                    placeholder="Selecione"
                    :items="agrupadores"
                    :disabled="isLoadingButton"
                    item-text="nome"
                    item-value="nome"
                    v-model="analise.agrupador"
                  ></v-select>

                  <p class="ma-0 mb-2 pa-0 v-card__text">
                    Qual a quantidade máxima de registros visualizados?
                  </p>
                  <v-row class="ma-0 pa-0">
                    <v-col cols="8" class="ma-0 pa-0">
                      <v-text-field
                        outlined
                        type="number"
                        dense
                        readonly
                        label="Nº de resultados"
                        v-model="analise.quantidadeResultados"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="ma-0 pa-0 pl-2">
                      <v-row class="ma-0 pa-0">
                        <v-btn
                          @click="
                            analise.quantidadeResultados =
                              parseInt(analise.quantidadeResultados) + 1
                          "
                          x-small
                          icon
                          color="primary"
                        >
                          <v-icon>mdi-menu-up</v-icon>
                        </v-btn>
                      </v-row>
                      <v-row class="ma-0 pa-0">
                        <v-btn
                          @click="
                            analise.quantidadeResultados =
                              analise.quantidadeResultados == 1
                                ? analise.quantidadeResultados
                                : parseInt(analise.quantidadeResultados) - 1
                          "
                          x-small
                          icon
                          color="primary"
                        >
                          <v-icon>mdi-menu-down</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>

                  <p class="ma-0 mb-2 pa-0 v-card__text">
                    Quantas casas decimais serão visualizadas?
                  </p>
                  <v-select
                    dense
                    clearable
                    outlined
                    placeholder="Selecione"
                    :items="possiveisCasasDecimais"
                    :disabled="isLoadingButton"
                    v-model="analise.qtdCasasDecimais"
                  ></v-select>

                  <p class="ma-0 mb-2 pa-0 v-card__text">
                    Qual a unidade de medida (R$ padrão)?
                  </p>
                  <v-text-field
                    outlined
                    placeholder="Preencha apenas para quantidades não monetárias."
                    counter="5"
                    dense
                    v-model="analise.unidade"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <p class="ma-0 mb-2 pa-0 v-card__text">Selecione as áreas:</p>
                  <v-select
                    dense
                    clearable
                    outlined
                    placeholder="Selecione"
                    :items="areas"
                    :disabled="isLoadingButton"
                    item-text="nome"
                    item-value="id"
                    multiple
                    v-model="analise.areas"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <p class="ma-0 mb-2 pa-0 v-card__text">
                    Selecione os perfis de visualização:
                  </p>
                  <v-select
                    dense
                    clearable
                    outlined
                    placeholder="Selecione"
                    :items="perfis"
                    :disabled="isLoadingButton"
                    item-text="nome"
                    item-value="id"
                    multiple
                    v-model="analise.perfis"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <p class="ma-0 mb-2 pa-0 v-card__text">
                    Adicione ou gerencia observações:
                  </p>

                  <v-textarea
                    outlined
                    placeholder="Anotações"
                    counter="1000"
                    dense
                    v-model="analise.anotacoes"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 mt-5" justify="start" v-if="!!idAnalise">
        <p class="v-card__text ma-0 pa-0">Arquivo contendo os dados (excel):</p>
        <v-col class="ma-0 pa-0" cols="4">
          <v-file-input
            :disabled="isLoadingButton"
            accept="application/csv"
            placeholder="Arquivo CSV"
            v-model="arquivo"
            hide-details
            dense
            :rules="rules"
            outlined
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-5 pa-0" v-if="!!tipoPeriodo">
        <v-col class="ma-0 pa-0" cols="4">
          <seletor-periodo
            :selecaoMultipla="false"
            :tipoPeriodoFixo="tipoPeriodo"
            class="mb-4"
            @changed="selecionarPeriodo"
          ></seletor-periodo>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 mt-5">
        <v-btn
          :disabled="desabilitarSalvarDados"
          :loading="isLoadingButton"
          @click="salvarDados()"
          color="primary"
          >Processar arquivo</v-btn
        >
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import parametrosTabelaAnalises from "@/constants/parametrosTabelaAnalises.constants.js";
export default {
  computed: {
    ...mapGetters("analises", ["mesesSelecionados", "analisesPorAreas", "analisesExternas"]),
    ...mapGetters(["isLoadingButton", "isLoading", "areasPerfis"]),
    analisesDisponiveisApresentacao() {
      let possiveisAnalisesPorTipo = this.analises.filter((i) => [1,2,3,4,5].includes(i.tipo)) ;
      return possiveisAnalisesPorTipo.map((i) => i.nome);
    },
    desabilitarSalvarDados() {
      return !this.arquivo || !this.idAnalise;
    },
    dataInicialFormatada() {
      return this._obterDataFormatoBrasil(this.dataInicial);
    },
    dataFinalFormatada() {
      return this._obterDataFormatoBrasil(this.dataFinal);
    },
  },
  methods: {
    ...mapActions([
      "salvarApresentacao",
      "salvarArquivo",
      "deletarAnalise",
      "salvarEdicaoCriacaoAnalise",
      "obterConfiguracoesAsync",
    ]),
    ...mapActions("analises", ["obterListaDeAnalises", "obterAnalisePorId"]),
    ...mapMutations(["setLoadingButton"]),
    selecionarAnalisesApresentacao(itens) {
      this.analisesApresentacao = itens;
    },
    selecionarPeriodo(periodo) {
      this.periodo = periodo;
    },
    salvarDados: async function () {
      const base64 = await this._converterArquivoBase64(this.arquivo);
      await this.salvarArquivo({
        idAnalise: this.idAnalise,
        arquivo: base64.split("base64,")[1],
      });
      this.arquivo = null;
    },
    salvarApresentacaoAsync: async function () {
      if (!this.analisesApresentacao && !this.analisesApresentacao.length) {
        return window.showError(
          "Selecione pelo menos uma análise para a apresentação."
        );
      }
      if (!this.analise.nome || this.analise.nome.length > 60) {
        return window.showError("Nome inválido.");
      }
      let analisesApresentacao = this.analisesApresentacao
        .map((nome) => {
          let analiseEncontrada = this.analises.find(
            (obj) => obj.nome === nome
          );
          if (analiseEncontrada) {
            return {
              tipo: analiseEncontrada.tipo,
              nome: analiseEncontrada.nome,
              id: analiseEncontrada.id,
            };
          }
          return null;
        })
        .filter((item) => item !== null);

      await this.salvarApresentacao({
        nome: this.analise.nome,
        analisesApresentacao,
        tipo: this.analise.tipo,
      });
      this.criacaoEdicaoAnalisesModel = false;
      this.limparDadosAnaliseEdicao();
    },
    salvarEdicaoCriacaoAnaliseAsync: async function () {
      if (this.analise.tipo === 6) {
        return this.salvarApresentacaoAsync();
      }
      let analise = {
        ...this.analise,
        tipoPeriodo: this.analise.periodos.tipoPeriodo,
        unidade: this.analise.unidade || null,
        dataFixa: this.analise.dataFixa ? new Date().toISOString() : null,
        quantidadeResultados: parseInt(this.analise.quantidadeResultados),
        areas: this.analise.areas || [],
        perfis: this.analise.perfis || [],
        qtdCasasDecimais: this.analise.qtdCasasDecimais || 0,
        tipo: this.analise.tipo,
      };
      if (!!analise.anotacoes && analise.anotacoes.length > 1000) {
        return alert("Digite no máx 1000 caracteres em obs.");
      }
      if (!analise.tipoPeriodo) {
        return alert("Selecione pelo menos um tipo de Período válido.");
      }
      if (!analise.filtrarSaida && !analise.filtrarEntrada) {
        return alert(
          "Selecione pelo menos um filtro de Receita e/ou de Despesa."
        );
      }
      if (!analise.filtrarQuitado && !analise.filtrarPendente) {
        return alert(
          "Selecione pelo menos um filtro de Quitado e/ou Pendente."
        );
      }
      if (analise.tipoPeriodo == 1) {
        if (
          !this._obterDiferencaDeDias(this.analise.periodos.dataInicio) ||
          !this._obterDiferencaDeDias(this.analise.periodos.dataFim)
        ) {
          return alert("Selecione uma data de início e fim válidas.");
        }
        analise["dataInicio"] = this._obterDiferencaDeDias(
          this.analise.periodos.dataInicio
        );
        analise["dataFim"] = this._obterDiferencaDeDias(
          this.analise.periodos.dataFim
        );
      } else if (analise.tipoPeriodo == 2) {
        analise["meses"] = [];
        if (this.analise.periodos.meses.length === 0) {
          return alert("Selecione pelo menos um mês.");
        }
        this.analise.periodos.meses.forEach(({ mes, ano }) => {
          let hoje = new Date();
          const dataRef = new Date(`${ano}-${mes}-1`);
          const inverse = dataRef.getTime() > hoje.getTime();
          let diff = 0;
          while (mes != hoje.getMonth() + 1 || ano != hoje.getFullYear()) {
            diff += inverse ? 1 : -1;
            if (
              (hoje.getMonth() + 1 == 12 && inverse) ||
              (hoje.getMonth() + 1 == 1 && !inverse)
            ) {
              hoje = new Date(
                `${hoje.getFullYear() + (inverse ? 1 : -1)}-${
                  inverse ? "1" : "12"
                }-1`
              );
            } else {
              hoje = new Date(
                `${hoje.getFullYear()}-${
                  hoje.getMonth() + 1 + (inverse ? 1 : -1)
                }-1`
              );
            }
          }
          analise["meses"].push(diff);
        });
      } else if (analise.tipoPeriodo == 3) {
        if (this.analise.periodos.anos.length === 0) {
          return alert("Selecione pelo menos um ano.");
        }
        analise["anos"] = [];
        const anoHoje = new Date().getFullYear();
        this.analise.periodos.anos.forEach((ano) => {
          analise["anos"].push(parseInt(ano) - anoHoje);
        });
      }
      this.criacaoEdicaoAnalisesModel = false;
      let colunas = [];
      this.obrigatorios.forEach((o) => {
        colunas.push(o.nome);
      });
      if (this.analise.agrupador) {
        colunas.push(this.analise.agrupador.nome);
      }
      const response = await this.salvarEdicaoCriacaoAnalise({
        nome: this.analise.nome,
        analise,
        colunas,
      });
      if (response) {
        const tipoCriado = response.tiposArquivo.find((t) => {
          return t.nome === analise.nome;
        });
        this.analises.push({
          nome: tipoCriado.nome,
          id: tipoCriado.id,
          ...tipoCriado,
        });
      }
      this.criacaoEdicaoAnalisesModel = false;
      this.limparDadosAnaliseEdicao();
    },
    limparDadosAnaliseEdicao() {
      this.analise = {
        periodos: null,
        agrupador: null,
        filtrarEntrada: true,
        filtrarSaida: true,
        filtrarQuitado: true,
        filtrarPendente: true,
        dataFixa: false,
        quantidadeResultados: 10,
        qtdCasasDecimais: null,
        unidade: null,
        areas: [],
        anotacoes: "",
        perfis: [],
        tipo: null,
        nome: null,
        esconderTotaisColuna: false,
        esconderTotaisLinha: false,
        esconderPercentuais: false,
      };
    },
    carregarDadosAnalisesAsync: async function () {
      this.setLoadingButton(true);
      await this.obterListaDeAnalises(true);
      this.areas = this.areasPerfis.areas;
      this.perfis = this.areasPerfis.perfis;
      if (this.analisesPorAreas[0]) {
        this.analisesPorAreas[0].analises.forEach((t) => {
          this.analises.push({
            nome: t.nome,
            id: t.id,
            ...t,
          });
        });
      }

      this.analisesExternas.forEach(t => {
        this.analises.push({
          nome: t.nome,
          ...t,
        }) 
      });

      this.setLoadingButton(false);
    },
  },
  watch: {
    analise(analise) {
      if (!!analise.perfil && Number.isInteger(analise.perfil)) {
        analise.perfil = [analise.perfil]
      }
    },
    dataInicial() {
      this.dataFinal = null;
    },
    idAnalise() {
      this.arquivosProcessamento = [];
      this.tipoPeriodo = null;
      this.periodo = null;
    },
    criacaoEdicaoAnalisesModel: async function (val) {
      if (
        !!this.idAnalise &&
        this.analises.find((a) => a.id === this.idAnalise).tipo === 6
      ) {
        this.analise = this.analises.find((a) => a.id === this.idAnalise);
        this.$nextTick(() => {
          this.triggerAnalisesApresentacao =
            this.analise.analisesApresentacao.map((a) => {
              return a.nome;
            });
        });
      } else if (val && !!this.idAnalise) {
        this.analise = await this.obterAnalisePorId({
          idAnalise: this.idAnalise,
        });
        if (typeof this.analise.esconderTotais === "boolean") {
          this.analise["esconderTotaisColuna"] = this.analise.esconderTotais;
          this.analise["esconderTotaisLinha"] = this.analise.esconderTotais;
          delete this.analise.esconderTotais;
        }
        this.analise = {
          ...this.analise,
          dataFixa: !!this.analise.dataFixa,
        };
        this.$nextTick(() => {
          this.triggerPeriodo = this.analise.periodos;
        });
      } else {
        this.limparDadosAnaliseEdicao();
      }
    },
  },
  data() {
    return {
      possiveisCasasDecimais:
        parametrosTabelaAnalises.obterPossiveisCasasDecimais(),
      obrigatorios: parametrosTabelaAnalises.obterObrigatorios(),
      agrupadores: parametrosTabelaAnalises.obterAgrupadores(),
      criacaoEdicaoAnalisesModel: false,
      triggerPeriodo: null,
      analise: null,
      idAnalise: null,
      tipoPeriodo: null,
      arquivo: null,
      arquivosProcessamento: [],
      periodo: null,
      areas: [],
      perfis: [],
      analises: [],
      rules: [
        (value) =>
          !value ||
          value.size < 5000000 ||
          "Arquivo não pode ser maior que 5MB!",
      ],
      analisesApresentacao: [],
      triggerAnalisesApresentacao: null,
    };
  },
  created() {
    this.carregarDadosAnalisesAsync();
  },
};
</script>
