export default {
    setApresentacao: (state, apresentacao) => {
        state.apresentacao = apresentacao
    },
    setFiltro: (state, filtro) => {
        state.filtro = filtro
    },
    setDadosGrafico: (state, dadosGrafico) => {
        state.dadosGrafico = dadosGrafico
    },
    setDadosTabela: (state, dadosTabela) => {
        state.dadosTabela = dadosTabela
    },
    setPeriodos: (state, periodos) => {
        state.periodos = periodos
    },
    setLancamentos: (state, lancamentos) => {
        state.lancamentos = lancamentos
    },
    setItensDistintos: (state, itensDistintos) => {
        state.itensDistintos = itensDistintos
    },
    setDataUltimaAtualizacaoAnalise: (state, dataUltimaAtualizacaoAnalise) => {
        state.dataUltimaAtualizacaoAnalise = dataUltimaAtualizacaoAnalise
    },
    setAnaliseExternaSelecionada: (state, analiseExternaSelecionada) => {
        state.analiseExternaSelecionada = analiseExternaSelecionada
    },
    setFeed: (state, feed) => {
        state.feed = feed
    },
    setSemPermissaoVisualizacaoAnalise: (state, semPermissaoVisualizacaoAnalise) => {
        state.semPermissaoVisualizacaoAnalise = semPermissaoVisualizacaoAnalise
    },
    setErroVisualizacaoAnalise: (state, erroVisualizacaoAnalise) => {
        state.erroVisualizacaoAnalise = erroVisualizacaoAnalise
    },
}