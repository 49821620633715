<template>
  <v-row class="ma-0 pa-0 mb-4" justify="left">
    <p class="ma-0 pa-0 text-h6 grey--text text--darken-3 font-weight-bold">
      {{ $route.meta.nome }}
    </p>
  </v-row>
</template>

<script>
export default {};
</script>
