<template>
  <div no-gutters class="mt-6 pb-10" justify="space-between">
    <v-row
      v-if="dadosTabela && Object.keys(dadosTabela).length > 0"
      class="ma-0 pa-0"
      justify="center"
    >
      <table class="tabela-wrapper">
        <tr class="grey lighten-2">
          <th
            v-for="(cabecalho, indice) in dadosTabela.cabecalho"
            :key="`${cabecalho}-${indice}`"
            v-bind:class="
              !indice
                ? 'grey lighten-2  py-0 primeira-celula'
                : 'grey lighten-2 tabela-cabecalho py-0'
            "
          >
            <p class="ma-0 pa-0 text-uppercase font-weight-medium">
              {{ cabecalho }}
            </p>
          </th>
        </tr>
        <tr
          v-for="(linha, indiceLinha) in dadosTabela.linhas"
          :key="`linha-${indiceLinha}`"
          v-bind:style="obterEstiloLinha(linha, indiceLinha)"
          class="white"
        >
          <td
            v-for="(coluna, indiceColuna) in linha.colunas"
            :key="`coluna-${indiceColuna}-linha-${indiceLinha}`"
            v-bind:style="
              obterEstiloColuna(linha, indiceLinha, coluna, indiceColuna)
            "
            v-bind:class="
              obterClasseValoresColuna(indiceColuna, linha.colunas.length)
            "
          >
            {{ coluna }}
          </td>
        </tr>
        <tr v-if="dadosTabela.rodape" class="grey lighten-2">
          <th
            v-for="(rodape, indice) in dadosTabela.rodape"
            :key="`${rodape}-${indice}`"
            v-bind:class="
              indice === 0
                ? 'font-weight-medium primeira-coluna grey lighten-2'
                : 'font-weight-regular'
            "
          >
            <span class="text-uppercase font-weight-medium">
              {{ rodape }}
            </span>
          </th>
        </tr>
      </table>
    </v-row>
    <v-row
      v-if="
        dadosTabela && Object.keys(dadosTabela).length > 0 && notasFormatadas
      "
      no-gutters
      justify="center"
    >
      <v-card elevation="0" outlined class="my-10 px-10 py-4 secondary">
        <v-icon color="black" class="mb-4">mdi-text</v-icon>
        <p
          v-if="notasFormatadas"
          class="text-left ma-0 pa-0 black--text"
          v-html="notasFormatadas"
        ></p>
      </v-card>
    </v-row>
  </div>
</template>

<style scoped>
.tabela-wrapper {
  /* max-height: 200px; */
  max-width: 100%;
}
.tabela-cabecalho {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
.primeira-coluna {
  position: -webkit-sticky;
  position: sticky;
  left: 0 !important;
  z-index: 1;
}
.primeira-celula {
  position: -webkit-sticky;
  position: sticky;
  left: 0 !important;
  z-index: 2;
  top: 0;
}
td {
  text-align: left;
  padding: 8px;
  font-size: 14px;
  white-space: nowrap;
  width: 100%;
}
th {
  text-align: left;
  padding: 8px;
  font-size: 14px;
  white-space: nowrap;
  word-wrap: break-word;
  width: 100%;
}
table {
  border: 1px solid #dddddd;
  border-collapse: collapse;
  display: block;
  overflow-x: auto;
  overflow-y: auto;
  height: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  table-layout: fixed;
  z-index: 1;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Tabela",
  computed: {
    ...mapGetters("analises", ["filtro"]),
    notasFormatadas() {
      return (this.notas || "").replace(/\n/g, "<br>");
    },
  },
  data() {
    return {};
  },
  methods: {
    obterEstiloLinha(linha, _indiceLinha) {
      return `border-top: ${(linha.nivel || 0) == 0 ? 1 : 0}px solid #dddddd;
      font-weight: ${linha.negrito ? "bold" : "normal"}`;
    },
    obterEstiloColuna(linha, _indiceLinha, _coluna, indiceColuna) {
      return `padding: 0px 8px 0px ${
        indiceColuna == 0 ? 8 + (linha.nivel || 0) * 16 : 8
      }px;`;
    },
    obterClasseValoresColuna(indiceColuna, tamanhoLinha) {
      if (indiceColuna === 0) return "font-weight-medium primeira-coluna white";
      if (
        !this.filtro.esconderTotaisLinha &&
        ((!this.filtro.esconderPercentuais &&
          tamanhoLinha - indiceColuna <= 2) ||
          (!!this.filtro.esconderPercentuais &&
            tamanhoLinha - indiceColuna <= 1))
      ) {
        return "font-weight-medium white";
      }
      return "font-weight-regular";
    },
  },
  props: {
    dadosTabela: { type: Object, required: true, default: {} },
    notas: { type: String, required: false, default: "" },
  },
};
</script>
