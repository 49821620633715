import Vue from "vue";
import Vuex from "vuex";
import modules from './modules';
import router from "@/router/index.js";
import configuracoesServices from "@/services/configuracoes.services.js";
import cargasServices from "@/services/cargas.services.js";
import firebase from "firebase/compat/app";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoadingButton: false,
    isLoading: false,
    alert: null,
    tokenUsuario: null,
    email: '',
    areasPerfis: null,
    abaHome: 1,
  },
  mutations: {
    setLoadingButton: (state, isLoadingButton) => { state.isLoadingButton = isLoadingButton; },
    setLoading: (state, isLoading) => { state.isLoading = isLoading; },
    setAlert: (state, { text, color }) => { state.alert = text && color ? { text, color } : null; },
    unsetAlert: (state) => { state.alert = null },
    setTokenUsuario: (state, tokenUsuario) => { state.tokenUsuario = tokenUsuario; },
    setEmail: (state, email) => {
      state.email = email;
      window.localStorage.setItem('acc_eml', email);
    },
    setAreasPerfis: (state, areasPerfis) => { state.areasPerfis = areasPerfis },
    setAbaHome: (state, abaHome) => { state.abaHome = abaHome },
  },
  getters: {
    isLoadingButton: state => state.isLoadingButton,
    isLoading: state => state.isLoading,
    alert: state => state.alert,
    tokenUsuario: state => state.tokenUsuario,
    email: state => { return state.email || window.localStorage.getItem('acc_eml') },
    areasPerfis: state => state.areasPerfis,
    abaHome: state => state.abaHome,
  },
  actions: {
    showError({ commit }, text) { commit("setAlert", { text, color: "black" }); },
    showSuccess({ commit }, text) { commit("setAlert", { text, color: "primary" }); },
    redirect(_context, name) { router.replace({ name: name }); },
    obterConfiguracoesAsync: async function ({ dispatch }, { documento, proximoModuloAcao }) {
      const dados = await configuracoesServices.obterConfiguracaoAsync(documento);
      if (proximoModuloAcao) {
        dispatch(proximoModuloAcao, dados, { root: true });
      } else {
        return dados;
      }
    },
    obterAreasEPerfis: async function ({ dispatch, commit }) {
      let areasPerfis = await dispatch('obterConfiguracoesAsync', {
        documento: "areas_perfis",
      });
      areasPerfis.areas = areasPerfis.areas.sort(function (a, b) {
        return a['nome'] > b['nome'] ? 1 : -1;
      })
      commit('setAreasPerfis', areasPerfis);
    },
    salvarApresentacao: async ({ state }, { nome, analisesApresentacao, tipo }) => {
      try {
        state.isLoading = true;
        await cargasServices.salvarApresentacao({ nome, analisesApresentacao, tipo });
        window.showSuccess("Análise salva com sucesso.");
      } catch (err) {
        window.showError(err);
      } finally {
        state.isLoading = false;
      }
    },
    salvarEdicaoCriacaoAnalise: async ({ state }, { nome, colunas, analise }) => {
      if (!nome || nome.length > 60) {
        window.showError("Nome inválido.");
        return false;
      }
      if (colunas.length == 0) {
        window.showError("Número de colunas inválido.");
        return false;
      }
      try {
        state.isLoading = true;
        analise = await cargasServices.salvarEdicaoCriacaoAnalise({ nome, colunas, analise });
        window.showSuccess("Análise salva com sucesso.");
        return analise;
      } catch (err) {
        window.showError(err);
      } finally {
        state.isLoading = false;
      }
    },

    salvarAnotacoesAnalise: async function ({ state }, { id, anotacoes = '', notas = '' }) {
      try {
        anotacoes = anotacoes || '';
        notas = notas || '';
        state.isLoading = true;
        await cargasServices.salvarAnotacoesAnalise({ id, anotacoes, notas });
        window.showSuccess("Anotações salvas com sucesso.");
        return true;
      } catch (err) {
        window.showError('Ocorreu um erro ao salvar suas anotações, por favor tente novamente mais tarde.');
        return false;
      } finally {
        state.isLoading = false;
      }
    },

    salvarArquivo: async function ({ state }, { arquivo, idAnalise }) {
      state.isLoadingButton = true;
      try {
        let payload = await cargasServices.salvarArquivo({ arquivo, idAnalise });
        window.showSuccess("Arquivo salvo com sucesso, aguarde um momento enquanto ele é processado.");
        return payload;
      } catch {
        window.showError("Erro ao salvar arquivo, por favor tente novamente mais tarde.");
      } finally {
        state.isLoadingButton = false;
      }
    },
    deletarAnalise: async function ({ state }, idAnalise) {
      if (!confirm("Você tem certeza que deseja prosseguir com a deleção total de todas as bases e filtros rápidos?")) {
        return;
      }
      state.isLoadingButton = true;
      try {
        await cargasServices.deletarAnalise(idAnalise);
        window.showSuccess("Gestão de dados atualizada e limpa com sucesso");
        window.location.reload();
      } catch {
        window.showError("Erro ao processar sua requisição, por favor tente novamente mais tarde.");
      } finally {
        state.isLoadingButton = false;
      }
    },
    compartilhar: async function () {
      let copyElement = document.getElementById("copy-to-clipboard");
      copyElement.classList.remove("copy-to-clipboard");
      copyElement.style.display = 'block';
      copyElement.value = window.location.href;
      copyElement.select();
      document.execCommand("copy");
      copyElement.style.display = 'none';
      window.showSuccess("Link de compartilhamento copiado para a área de transferência.");
    }
  },
  modules
});
