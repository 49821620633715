const root = { root: true };
import analisesServices from '@/services/analises.services.js'
import mixin from '@/mixin/index.js';
const _obterNomeMesDoNumero = mixin.methods._obterNomeMesDoNumero;
const _ordenarAgrupamentos = function (agrupamentos, filtro) {
    if (!agrupamentos || agrupamentos.length == 0)
        return [];

    const labelOrdenacao = filtro.labelFiltroOrdenacao;
    const ordemCrescente = filtro.ordemCrescente;

    let arrayBaseOrdenacao = [];
    let baseOrdenada = [];
    let novoAgrupamento = [];

    agrupamentos.forEach(a => {
        arrayBaseOrdenacao.push(a.dados?.filter(d => d.label === labelOrdenacao)[0].valor);
    });

    const setValoreOrdenacao = new Set(arrayBaseOrdenacao);
    arrayBaseOrdenacao = [...setValoreOrdenacao];

    baseOrdenada = ordemCrescente ?
        arrayBaseOrdenacao.sort(function (a, b) { return a - b })
        : arrayBaseOrdenacao.sort(function (a, b) { return b - a });

    baseOrdenada.forEach(d => {
        agrupamentos.forEach((agrupamento, indice) => {
            if (agrupamento.dados?.filter(d => d.label === labelOrdenacao)[0].valor === d)
                novoAgrupamento.push(agrupamento)
        });
    });
    if (!!filtro.quantidadeResultados) {
        let qtdResultados = !filtro.quantidadeResultados ? 0 :
            (parseInt(filtro.quantidadeResultados) < 0 ? 0 : (novoAgrupamento.length < parseInt(filtro.quantidadeResultados) ?
                novoAgrupamento.length : parseInt(filtro.quantidadeResultados)));
        return novoAgrupamento.slice(0, qtdResultados);
    }

    return novoAgrupamento;
}

const _obterAgrupamentosDiarios = function (dataInicioIso, dataFimIso, lancamentos, filtro) {
    let agrupamentos = [];
    let dataInicio = new Date(dataInicioIso);
    let dataFim = new Date(dataFimIso);

    let somaLancamentosPeriodo = lancamentos?.map(d => d.valor)?.reduce((a, b) => a + b, 0);

    for (let iGrupoRef = 0; iGrupoRef < filtro.itensDistintosSelecionados.length; iGrupoRef++) {
        let grupoRef = filtro.itensDistintosSelecionados[iGrupoRef];
        let somaValoresAbsolutoGrupo = 0;

        let agrupamento = {
            "nome": grupoRef,
            "dados": []
        }

        for (let diaRef = dataInicio; diaRef <= dataFim; diaRef.setDate(diaRef.getDate() + 1)) {
            let dadosMoedaAgrupamento = {};
            let dadosPercentualAgrupamento = {};

            let lancamentosDia = lancamentos.filter(d => d.data_referencia == diaRef.toISOString());
            let lancamentosDiaGrupo = lancamentosDia?.filter(d => d.agrupador == grupoRef);
            let somaAbsolutaDia = lancamentosDia.map(d => d.valor)?.reduce((a, b) => a + b, 0);

            dadosMoedaAgrupamento.label = mixin.methods._obterDataFormatoBrasilDeUtcString(diaRef) + ` (${filtro.unidade || 'R$'})`;
            dadosMoedaAgrupamento.valor = lancamentosDiaGrupo.map(d => d.valor)?.reduce((a, b) => a + b, 0) ?? 0;
            somaValoresAbsolutoGrupo += dadosMoedaAgrupamento.valor;

            dadosPercentualAgrupamento.label = mixin.methods._obterDataFormatoBrasilDeUtcString(diaRef) + ' (%)';
            dadosPercentualAgrupamento.valor = (somaAbsolutaDia != null && somaAbsolutaDia != 0) ? dadosMoedaAgrupamento.valor / somaAbsolutaDia : 0;

            agrupamento.dados.push(dadosMoedaAgrupamento);
            agrupamento.dados.push(dadosPercentualAgrupamento);
        }

        let dadosTotalAbsoluto = { "label": `Total (${filtro.unidade || 'R$'})`, "valor": somaValoresAbsolutoGrupo };
        let dadosTotalRelativo = { "label": "Total (%)", "valor": somaValoresAbsolutoGrupo / somaLancamentosPeriodo };

        agrupamento.dados.push(dadosTotalAbsoluto);
        agrupamento.dados.push(dadosTotalRelativo);

        agrupamentos.push(agrupamento);
        dataInicio = new Date(dataInicioIso);
    }

    return agrupamentos;
}

const _obterAgrupamentosMensais = function (lancamentos, meses, filtro) {
    let agrupamentos = [];
    let somaLancamentosPeriodo = lancamentos?.map(d => d.valor)?.reduce((a, b) => a + b, 0);

    for (let iGrupoRef = 0; iGrupoRef < filtro.itensDistintosSelecionados.length; iGrupoRef++) {
        let grupoRef = filtro.itensDistintosSelecionados[iGrupoRef];
        let somaValoresAbsolutoGrupo = 0;

        let agrupamento = {
            "nome": grupoRef,
            "dados": []
        }

        meses.forEach(mesFiltro => {
            let anoRef = mesFiltro.ano;
            let mesRef = mesFiltro.mes;

            let dadosMoedaAgrupamento = {};
            let dadosPercentualAgrupamento = {};

            let lancamentosMes =
                lancamentos.filter(d => d.mes_referencia.ano == anoRef && d.mes_referencia.mes == mesRef);

            let lancamentosMesGrupo = lancamentosMes?.filter(d => d.agrupador == grupoRef);

            let somaAbsolutaMes = lancamentosMes.map(d => d.valor)?.reduce((a, b) => a + b, 0);

            dadosMoedaAgrupamento.label = `${_obterNomeMesDoNumero(mesRef, true)}/${(anoRef).toString().slice(2, 4)} (${filtro.unidade || 'R$'})`;
            dadosMoedaAgrupamento.valor = lancamentosMesGrupo.map(d => d.valor)?.reduce((a, b) => a + b, 0) ?? 0;
            somaValoresAbsolutoGrupo += dadosMoedaAgrupamento.valor;

            dadosPercentualAgrupamento.label = `${_obterNomeMesDoNumero(mesRef, true)}/${(anoRef).toString().slice(2, 4)} (%)`;
            dadosPercentualAgrupamento.valor = (somaAbsolutaMes != null && somaAbsolutaMes != 0) ? dadosMoedaAgrupamento.valor / somaAbsolutaMes : 0;

            agrupamento.dados.push(dadosMoedaAgrupamento);
            agrupamento.dados.push(dadosPercentualAgrupamento);
        });

        let dadosTotalAbsoluto = { "label": `Total (${filtro.unidade || 'R$'})`, "valor": somaValoresAbsolutoGrupo };
        let dadosTotalRelativo = { "label": "Total (%)", "valor": somaValoresAbsolutoGrupo / somaLancamentosPeriodo };

        agrupamento.dados.push(dadosTotalAbsoluto);
        agrupamento.dados.push(dadosTotalRelativo);

        agrupamentos.push(agrupamento);
    }

    return agrupamentos;
}

const _obterAgrupamentosAnuais = function (lancamentos, anos, filtro) {
    let agrupamentos = [];
    let somaLancamentosPeriodo = lancamentos?.map(d => d.valor)?.reduce((a, b) => a + b, 0);

    for (let iGrupoRef = 0; iGrupoRef < filtro.itensDistintosSelecionados.length; iGrupoRef++) {
        let grupoRef = filtro.itensDistintosSelecionados[iGrupoRef];
        let somaValoresAbsolutoGrupo = 0;

        let agrupamento = {
            "nome": grupoRef,
            "dados": []
        }

        anos.forEach(anoFiltro => {
            let dadosMoedaAgrupamento = {};
            let dadosPercentualAgrupamento = {};

            let lancamentosAno =
                lancamentos.filter(d => d.ano_referencia == anoFiltro);

            let lancamentosAnoGrupo = lancamentosAno?.filter(d => d.agrupador == grupoRef);

            let somaAbsolutaAno = lancamentosAno.map(d => d.valor)?.reduce((a, b) => a + b, 0);

            dadosMoedaAgrupamento.label = `${anoFiltro} (${filtro.unidade || 'R$'})`;
            dadosMoedaAgrupamento.valor = lancamentosAnoGrupo[0]?.valor ?? 0;
            somaValoresAbsolutoGrupo += dadosMoedaAgrupamento.valor;

            dadosPercentualAgrupamento.label = `${anoFiltro} (%)`;
            dadosPercentualAgrupamento.valor = (somaAbsolutaAno != null && somaAbsolutaAno != 0) ? dadosMoedaAgrupamento.valor / somaAbsolutaAno : 0;

            agrupamento.dados.push(dadosMoedaAgrupamento);
            agrupamento.dados.push(dadosPercentualAgrupamento);
        });

        let dadosTotalAbsoluto = { "label": `Total (${filtro.unidade || 'R$'})`, "valor": somaValoresAbsolutoGrupo };
        let dadosTotalRelativo = { "label": "Total (%)", "valor": somaValoresAbsolutoGrupo / somaLancamentosPeriodo };

        agrupamento.dados.push(dadosTotalAbsoluto);
        agrupamento.dados.push(dadosTotalRelativo);

        agrupamentos.push(agrupamento);
    }

    return agrupamentos;
}

const _obterAgrupamentosSemPeriodo = function (lancamentos, filtro) {
    let agrupamentos = [];
    let somaLancamentosPeriodo = lancamentos?.map(d => d.valor)?.reduce((a, b) => a + b, 0);

    for (let iGrupoRef = 0; iGrupoRef < filtro.itensDistintosSelecionados.length; iGrupoRef++) {
        let grupoRef = filtro.itensDistintosSelecionados[iGrupoRef];
        let somaValoresAbsolutoGrupo = 0;

        let agrupamento = {
            "nome": grupoRef,
            "dados": []
        }

        let dadosMoedaAgrupamento = {};
        let dadosPercentualAgrupamento = {};
        let lancamentosGrupo = lancamentos?.filter(d => d.agrupador == grupoRef);
        let somaAbsoluta = lancamentos.map(d => d.valor)?.reduce((a, b) => a + b, 0);

        dadosMoedaAgrupamento.label = `(${filtro.unidade || 'R$'})`;
        dadosMoedaAgrupamento.valor = lancamentosGrupo[0]?.valor ?? 0;
        somaValoresAbsolutoGrupo += dadosMoedaAgrupamento.valor;

        dadosPercentualAgrupamento.label = `(%)`;
        dadosPercentualAgrupamento.valor = (somaAbsoluta != null && somaAbsoluta != 0) ? dadosMoedaAgrupamento.valor / somaAbsoluta : 0;

        agrupamento.dados.push(dadosMoedaAgrupamento);
        agrupamento.dados.push(dadosPercentualAgrupamento);

        let dadosTotalAbsoluto = { "label": `Total (${filtro.unidade || 'R$'})`, "valor": somaValoresAbsolutoGrupo };
        let dadosTotalRelativo = { "label": "Total (%)", "valor": somaValoresAbsolutoGrupo / somaLancamentosPeriodo };

        agrupamento.dados.push(dadosTotalAbsoluto);
        agrupamento.dados.push(dadosTotalRelativo);

        agrupamentos.push(agrupamento);
    }

    return agrupamentos;
}

const gerarGraficoComTotalizadores = function (filtro, linhas, state, labels) {
    let numCasasDecimais = filtro.qtdCasasDecimais ?? 0;

    if (!filtro.esconderTotaisLinha) {
        const totaisDataset = [];
        linhas.forEach((linha, numeroLinha) => {
            let valorTotal = linha.colunas[linha.colunas.length - (filtro.esconderPercentuais ? 1 : 2)];
            valorTotal = mixin.methods._formatarStringParaNumero(valorTotal);
            const itemTotal = linha.colunas[0];

            totaisDataset.push({
                label: itemTotal,
                type: 'bar',
                data: [valorTotal],
                fill: false,
                borderColor: mixin.methods._obterCorParaItemGrafico(numeroLinha, itemTotal),
                backgroundColor: mixin.methods._obterCorParaItemGrafico(numeroLinha, itemTotal),
                order: 1,
                tension: 0,
                pointRadius: 4,
                borderWidth: 3
            });
        });

        state.dadosGraficoTotalizadores = {
            type: "bar",
            data: { labels: [''], datasets: totaisDataset },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: `(Totais)  ${filtro.nome}` || 'Totais',
                    fontSize: 20
                },

                scales: {
                    yAxes: [{
                        id: "bar-y-axis1",
                        stacked: false,
                        ticks: {
                            beginAtZero: true,
                            callback: function (label, _index, _labels) {
                                return mixin.methods._formatarNumeroParaUnidadeArredondado(label, " ");
                            }
                        },
                        scaleLabel: {
                            display: true,
                            labelString: filtro.unidade || 'R$',
                            fontColor: '#666',
                            fontSize: 20
                        },
                    }]
                },
                legend: {
                    position: 'bottom'
                },
                tooltips: {
                    position: 'average',
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, yData) {
                            const valor = mixin.methods._formatarNumeroParaUnidade(parseFloat(tooltipItem.value).toFixed(numCasasDecimais || 0), filtro.unidade);
                            return `${yData.datasets[tooltipItem.datasetIndex].label}: ${valor}`;
                        }
                    }
                }
            }
        };
    } else {
        state.dadosTotalizadores = null;
    }
}

const formatarDataDiaria = function (data) {
    return new Date(data).toISOString().split("T")[0]
}

export default {
    obterDadosAnalisePorId: async function ({ state, dispatch, commit }, { idAnalise, modulo, consultarPeriodo = false, agrupador = null }) {
        commit("setLoading", true, root);
        try {
            let requestBody = { periodos: null, agrupador: null, idAnalise };
            if (consultarPeriodo) {
                requestBody['periodos'] = {
                    "tipoPeriodo": state.periodos.tipoPeriodo,
                    "dataInicio": state.periodos.tipoPeriodo == 1 ? formatarDataDiaria(state.periodos.dataInicio) : null,
                    "dataFim": state.periodos.tipoPeriodo == 1 ? formatarDataDiaria(state.periodos.dataFim) : null,
                    "meses": state.periodos.tipoPeriodo == 2 ? state.periodos.meses : null,
                    "anos": state.periodos.tipoPeriodo == 3 ? state.periodos.anos : null
                }
                requestBody['agrupador'] = agrupador;
            }

            const payload = await analisesServices.obterDadosAnalisePorId(requestBody);

            state.periodos = requestBody['periodos'] || payload.periodos;
            let itensDistintos = [...new Set(payload.lancamentos.map(g => g.agrupador))];
            itensDistintos = itensDistintos.filter(d => d != null);

            commit("setLancamentos", payload.lancamentos);
            commit("setItensDistintos", itensDistintos);

            const dataUltimaModificacaoBaseFormatada = mixin.methods._obterDataFormatoBrasilDeUtcString(payload?.dataAtualizacaoBase, true);
            commit("setDataUltimaAtualizacaoAnalise", dataUltimaModificacaoBaseFormatada);

            const valoresAgrupadorPonderados = !!payload.valoresAgrupador && !!payload.valoresAgrupador.length ? payload.valoresAgrupador : itensDistintos;
            const acao = (payload.tipo === 1 ? 'consolidarAnaliseHorizontal' : payload.tipo === 2 ? 'consolidarAnalisePareto' : '');

            dispatch(`${modulo}/${acao}`, {
                itensDistintosSelecionados: valoresAgrupadorPonderados,
                labelFiltroOrdenacao: `Total (${payload.unidade || 'R$'})`,
                ordemCrescente: false,
                ...payload
            }, root);
            return {
                labelFiltroOrdenacao: `Total (${payload.unidade || 'R$'})`,
                ordemCrescente: false,
                ...payload,
            };
        } catch (err) {
            if(err.message === "403"){
                commit("setSemPermissaoVisualizacaoAnalise", true);
            } else {
                commit("setErroVisualizacaoAnalise", true);
            }
        }
        finally {
            commit("setLoading", false, root);
        }
    },

    filtrarResultadosAnalise: async function ({ state }, filtro) {
        let lancamentos = state.lancamentos;

        if (!filtro.filtrarEntrada)
            lancamentos = lancamentos.filter(d => d.entrada == false);

        if (!filtro.filtrarSaida)
            lancamentos = lancamentos.filter(d => d.entrada == true);

        if (!filtro.filtrarQuitado)
            lancamentos = lancamentos.filter(d => d.quitado == false);

        if (!filtro.filtrarPendente)
            lancamentos = lancamentos.filter(d => d.quitado == true);

        if (!!filtro.itensDistintosSelecionados && filtro.itensDistintosSelecionados.length > 0) {
            lancamentos = lancamentos.filter(d => filtro.itensDistintosSelecionados.includes(d.agrupador));
        }

        let agrupamentos = {
            1: _obterAgrupamentosDiarios(state.periodos.dataInicio, state.periodos.dataFim, lancamentos, filtro),
            2: _obterAgrupamentosMensais(lancamentos, state.periodos.meses || [], filtro),
            3: _obterAgrupamentosAnuais(lancamentos, state.periodos.anos || [], filtro),
            4: _obterAgrupamentosSemPeriodo(lancamentos, filtro)
        }[state.periodos.tipoPeriodo];
        return (filtro.labelFiltroOrdenacao && filtro.labelFiltroOrdenacao !== '')
            ? _ordenarAgrupamentos(agrupamentos, filtro) : agrupamentos;
    },

    consolidarAnaliseHorizontal: async function ({ dispatch, state, commit }, filtro) {
        commit("setLoading", true, root);
        
        try {
            if (typeof filtro.esconderTotais === "boolean") {
                filtro["esconderTotaisColuna"] = filtro.esconderTotais;
                filtro["esconderTotaisLinha"] = filtro.esconderTotais;
            }

            let numCasasDecimais = filtro.qtdCasasDecimais ?? 0;

            let agrupamentos = await dispatch('analises/filtrarResultadosAnalise', filtro, root)
            let tipoGrafico = 'line';

            let cabecalho = [], linhas = [], rodape = ["Total Geral"], labels = [], datasets = [];
            cabecalho = [""];
            agrupamentos.forEach((produto, indexProduto) => {
                let dados = [];
                let colunas = [];
                colunas.push(produto?.nome);

                produto.dados.forEach((agrupamento, indexAgrupamento) => {
                    let eixoPrimario = indexAgrupamento % 2 == 0;
                    let valorColuna;

                    if (eixoPrimario) {
                        dados.push(agrupamento.valor);
                    }

                    valorColuna = parseFloat(agrupamento?.valor * (eixoPrimario ? 1 : 100)).toFixed(numCasasDecimais);
                    valorColuna = mixin.methods._formatarNumeroParaUnidade(valorColuna, eixoPrimario ? " " : "%");

                    colunas.push(valorColuna);

                    if (indexProduto == 0) {
                        cabecalho.push(agrupamento.label);
                        if (indexAgrupamento % 2 == 0) {
                            labels.push(agrupamento.label);
                        }
                    }
                    if (typeof rodape[indexAgrupamento + 1] === 'number') {
                        rodape[indexAgrupamento + 1] =
                            rodape[indexAgrupamento + 1] + parseFloat(agrupamento.valor);
                    } else {
                        rodape.push(parseFloat(agrupamento.valor));
                    }
                });

                dados.pop();
                tipoGrafico = dados.length === 1 ? 'bar' : 'line';
                datasets.push({
                    label: produto?.nome,
                    type: tipoGrafico,
                    data: dados,
                    fill: false,
                    borderColor: mixin.methods._obterCorParaItemGrafico(indexProduto, produto?.nome),
                    backgroundColor: mixin.methods._obterCorParaItemGrafico(indexProduto, produto?.nome),
                    order: 1,
                    tension: 0,
                    pointRadius: 4,
                    borderWidth: 3
                })

                linhas.push({ nivel: 0, colunas });
            });
            labels.pop();
            for (let indiceRodape in rodape) {
                if (indiceRodape != 0) {
                    let valorRodape;
                    let eixoPrimario = (indiceRodape) % 2 !== 0;

                    valorRodape = parseFloat(rodape[indiceRodape] * (eixoPrimario ? 1 : 100)).toFixed(numCasasDecimais);
                    valorRodape = mixin.methods._formatarNumeroParaUnidade(valorRodape, eixoPrimario ? " " : "%");

                    rodape[indiceRodape] = valorRodape;
                }
            }
            if (filtro.esconderPercentuais) {
                cabecalho = cabecalho.filter(function (_, index) {
                    return index % 2 === 1 || index === 0;
                });
                linhas.forEach(linha => {
                    linha.colunas = linha.colunas.filter(function (_, index) {
                        return index % 2 === 1 || index === 0;
                    })
                });
                rodape = rodape.filter(function (_, index) {
                    return index % 2 === 1 || index === 0;
                });
            }
            if (filtro.esconderTotaisLinha) {
                if (!filtro.esconderPercentuais) {
                    rodape.pop();
                    cabecalho.pop();
                    linhas.forEach(linha => {
                        linha.colunas.pop();
                    });
                }
                cabecalho.pop();
                rodape.pop();
                linhas.forEach(linha => {
                    linha.colunas.pop();
                });
            }
            if (filtro.esconderTotaisColuna) {
                rodape = null;
            }

            state.dadosTabela = { cabecalho, linhas, rodape };
            state.dadosGraficoTotalizadores = null;
            let valorMinimo = null;
            datasets.forEach(d => {
                d.data.forEach(dado => {
                    if (typeof dado === 'number' && (valorMinimo == null || dado <= valorMinimo)) {
                        valorMinimo = dado;
                    }
                })
            });
            state.dadosGrafico = {
                type: tipoGrafico,
                data: { labels: labels, datasets: datasets },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            id: "bar-y-axis1",
                            stacked: false,
                            ticks: {
                                beginAtZero: false,
                                callback: function (label, _index, _labels) {
                                    return mixin.methods._formatarNumeroParaUnidadeArredondado(label, " ");
                                },
                                min: valorMinimo * (valorMinimo >= 0 ? 0.9 : 1.2)
                            },
                            scaleLabel: {
                                display: true,
                                labelString: filtro.unidade || 'R$',
                                fontColor: '#666',
                                fontSize: 20
                            },
                        }]
                    },
                    title: {
                        display: true,
                        text: filtro.nome || '',
                        fontSize: 20
                    },
                    legend: {
                        position: 'bottom'
                    },
                    tooltips: {
                        position: 'average',
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            label: function (tooltipItem) {
                                const valor = mixin.methods._formatarNumeroParaUnidade(parseFloat(tooltipItem.value || 0).toFixed(numCasasDecimais), filtro.unidade);
                                return `${datasets[tooltipItem.datasetIndex]?.label}: ${valor}`;
                            }
                        }
                    }
                }
            };
            if (tipoGrafico === 'line') {
                gerarGraficoComTotalizadores(filtro, linhas, state, labels);
            }
        } catch (err) {
        } finally {
            commit("setLoading", false, root);
        }

    },

    consolidarAnalisePareto: async function ({ dispatch, state, commit }, filtro) {
        commit("setLoading", true, root);
        
        try {

            if (typeof filtro.esconderTotais === "boolean") {
                filtro["esconderTotaisColuna"] = filtro.esconderTotais;
                filtro["esconderTotaisLinha"] = filtro.esconderTotais;
            }

            let numCasasDecimais = filtro.qtdCasasDecimais ?? 0;

            let agrupamentos = await dispatch('analises/filtrarResultadosAnalise', filtro, root);
            let numAgrupamentos = agrupamentos[0]?.dados?.length;
            let cabecalho = [], linhas = [], rodape = [], labels = [], datasets = [];
            cabecalho = [""];

            agrupamentos.forEach((produto, indexProduto) => {
                let colunas = [];
                colunas.push(produto.nome);

                produto.dados.forEach((agrupamento, indexAgrupamento) => {
                    let eixoPrimario = indexAgrupamento % 2 == 0;
                    let valorColuna;

                    valorColuna = parseFloat(agrupamento?.valor * (eixoPrimario ? 1 : 100)).toFixed(numCasasDecimais);
                    valorColuna = mixin.methods._formatarNumeroParaUnidade(valorColuna, eixoPrimario ? " " : "%");

                    colunas.push(valorColuna);

                    if (indexProduto == 0) {
                        cabecalho.push(agrupamento.label);
                    }
                });

                linhas.push({ nivel: 0, colunas });
            });

            for (let indexAgrupamento = 0; indexAgrupamento < numAgrupamentos; indexAgrupamento++) {
                let agrupamento = agrupamentos[0]?.dados[indexAgrupamento];
                let eixoPrimario = indexAgrupamento % 2 == 0;
                let tipoGrafico = eixoPrimario ? 'bar' : 'line';
                let eixoGrafico = eixoPrimario ? 'eixoY-barra' : 'eixoY-linha';
                let ordemGrafico = eixoPrimario ? 1 : 0;
                let dados = [];
                let valorItemRodape = 0;

                agrupamentos.forEach((produto, indexAgrupamentoProduto) => {
                    const valor = eixoPrimario ? parseFloat(produto.dados[indexAgrupamento].valor).toFixed(numCasasDecimais) : (produto.dados[indexAgrupamento].valor * 100).toFixed(numCasasDecimais)
                    dados.push(valor);

                    if (indexAgrupamento == 0) {
                        labels.push(produto?.nome);

                        if (indexAgrupamentoProduto == 0) {
                            rodape.push('Total Geral')
                        }
                    }

                    valorItemRodape += parseFloat(produto?.dados[indexAgrupamento]?.valor);
                });

                valorItemRodape = parseFloat(valorItemRodape * (eixoPrimario ? 1 : 100)).toFixed(numCasasDecimais);
                valorItemRodape = mixin.methods._formatarNumeroParaUnidade(valorItemRodape, eixoPrimario ? " " : "%");

                rodape.push(valorItemRodape);

                datasets.push({
                    label: agrupamento.label,
                    type: tipoGrafico,
                    data: dados,
                    fill: false,
                    backgroundColor: mixin.methods._obterCorParaItemGrafico(mixin.methods._obterIndiceCorGraficoMisto(indexAgrupamento), agrupamento.label),
                    borderColor: tipoGrafico === 'bar' ? undefined : mixin.methods._obterCorParaItemGrafico(mixin.methods._obterIndiceCorGraficoMisto(indexAgrupamento), agrupamento.label),
                    order: ordemGrafico,
                    tension: 0,
                    pointRadius: 4,
                    borderWidth: 1,
                    yAxisID: eixoGrafico
                })
            }

            let removerTotalizadoresGrafico = true;

            if (removerTotalizadoresGrafico) {
                datasets.pop()
                datasets.pop()
            }

            if (filtro.esconderPercentuais) {
                cabecalho = cabecalho.filter(function (_, index) {
                    return index % 2 === 1 || index === 0;
                });
                linhas.forEach(linha => {
                    linha.colunas = linha.colunas.filter(function (_, index) {
                        return index % 2 === 1 || index === 0;
                    })
                });
                rodape = rodape.filter(function (_, index) {
                    return index % 2 === 1 || index === 0;
                });
            }

            if (filtro.esconderTotaisLinha) {
                if (!filtro.esconderPercentuais) {
                    rodape.pop();
                    cabecalho.pop();
                    linhas.forEach(linha => {
                        linha.colunas.pop();
                    });
                }
                cabecalho.pop();
                rodape.pop();
                linhas.forEach(linha => {
                    linha.colunas.pop();
                });
            }
            if (filtro.esconderTotaisColuna) {
                rodape = null;
            }
            state.dadosTabela = { cabecalho, linhas, rodape };

            const tooltipSemPercentual = function (tooltipItem, data) {
                return `${data.datasets[tooltipItem.datasetIndex]?.label}: 
                ${mixin.methods._formatarNumeroParaUnidade(parseFloat(tooltipItem.value || 0).toFixed(numCasasDecimais), filtro.unidade)}`;
            };
            const tooltipComPercentual = function (tooltipItem) {
                let valorAbsoluto = tooltipItem.datasetIndex % 2 == 0;
                let valor = parseFloat(tooltipItem.value || 0);

                valor = mixin.methods._formatarNumeroParaUnidade(valor, (valorAbsoluto ? filtro.unidade : "%"));
                return `${datasets[tooltipItem.datasetIndex]?.label}: ${valor}`;
            }
            let dadosGrafico = {
                type: "bar",
                data: { labels, datasets },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    title: {
                        display: true,
                        text: filtro.nome || '',
                        fontSize: 20
                    },
                    scales: {
                        xAxes: [
                            {
                                id: "bar-x-axis2",
                                stacked: false,
                                scaleLabel: {
                                    display: true,
                                    labelString: '',
                                    fontColor: '#666',
                                    fontSize: 20
                                },
                            },
                        ],
                        yAxes: [
                            {
                                id: "eixoY-barra",
                                position: 'left',
                                color: '#666',
                                stacked: false,
                                ticks: {
                                    beginAtZero: true,
                                    callback: function (label, _index, _labels) {
                                        return mixin.methods._formatarNumeroParaUnidadeArredondado(label, " ");
                                    }
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: filtro.unidade || 'R$',
                                    fontColor: '#666',
                                    fontSize: 20
                                },
                            },
                            {
                                id: "eixoY-linha",
                                position: 'right',
                                stacked: false,
                                ticks: {
                                    beginAtZero: true
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: '%',
                                    fontColor: '#444',
                                    fontSize: 20
                                },
                            }
                        ]
                    },
                    legend: {
                        position: 'bottom'
                    },
                    tooltips: {
                        position: 'average',
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            label: !!filtro.esconderPercentuais ? tooltipSemPercentual : tooltipComPercentual,
                        }
                    }
                }
            };
            if (filtro.esconderPercentuais) {
                dadosGrafico.options.scales.yAxes.pop();
                dadosGrafico.data.datasets = dadosGrafico.data.datasets.filter(d => {
                    return d.yAxisID !== "eixoY-linha";
                })
            }
            state.dadosGrafico = dadosGrafico;
        } catch (err) {
        } finally {
            commit("setLoading", false, root);
        }
    },

    obterListaDeAnalises: async function ({ state, commit, dispatch, rootGetters }, atualizarFiltros = false) {
        commit("setLoading", true, root);
        try {
            if (!!atualizarFiltros) {
                commit('setAreasPerfis', null, root);
                state.analisesPorAreas = [];
            }
            if (!rootGetters['areasPerfis']) {
                await dispatch('obterAreasEPerfis', null, root);
            }
            if (!state.analisesPorAreas.length) {
                const { analises, analisesExternas } =
                    await analisesServices.obterListaDeAnalises();
                state.analisesExternas = analisesExternas;
                let analisesPorAreas = [{ nome: 'Todas', id: null, analises: [] }];
                rootGetters['areasPerfis'].areas.forEach(area => {
                    analisesPorAreas.push({ ...area, analises: [] });
                });
                analises.forEach(analise => {
                    if (analise.areas) {
                        analise.areas.forEach(area => {
                            analisesPorAreas.find(f => { return f.id === area }).analises.push(analise);
                        });
                    }
                    analisesPorAreas[0].analises.push(analise);
                });

                state.analisesPorAreas = analisesPorAreas.filter(f => { return !!f.analises.length });
            }

        } catch (err) {
        } finally {
            commit("setLoading", false, root);
        }
    },

    obterAnalisePorId: async function ({ commit }, { idAnalise }) {
        try {
            commit("setLoading", true, root);
            return await analisesServices.obterAnalisePorId(idAnalise);
        } finally {
            commit("setLoading", false, root);
        }
    },

    obterGraficoAnaliseDeTabela: async function ({ state }, { analise }) {
        const { tabelaConsolidada, tipo, nome } = analise;
        const { cabecalho, linhas } = tabelaConsolidada;
        let datasets = [], labels = [];
        if (tipo == 2) {
            return obterGraficoAnaliseParetoDeTabela(cabecalho, linhas, labels, datasets, analise);
        }
        else {
            return obterGraficoAnaliseHorizontalDeTabela(tabelaConsolidada, linhas, cabecalho, datasets, analise, nome);
        }
    },

    perguntarLunisAi: async function ({ state, commit }, { idAnalise, pergunta }) {
        return await analisesServices.perguntarLunisAi(idAnalise, pergunta, state.lancamentos);
    }

}

function obterGraficoAnaliseHorizontalDeTabela(tabelaConsolidada, linhas, cabecalho, datasets, analise, nome) {
    let labels = tabelaConsolidada.cabecalho.filter(c => {
        return !!c && !c.includes('Total') && (analise.esconderPercentuais || !c.includes('%'));
    });
    const tipoGrafico = labels.length === 1 ? 'bar' : 'line';
    let valorMinimo = null;
    linhas.forEach((linha, numeroLinha) => {
        let dados = [];
        for (let numeroColuna = 1; numeroColuna < linha['colunas'].length; numeroColuna++) {
            if (!cabecalho[numeroColuna].includes('Total') && (analise.esconderPercentuais || !cabecalho[numeroColuna].includes('%'))) {
                let valor = mixin.methods._formatarStringParaNumero(linha['colunas'][numeroColuna]);
                dados.push(valor);
                if (valorMinimo == null || valor <= valorMinimo) {
                    valorMinimo = valor;
                }
            }
        }
        datasets.push({
            label: linha['colunas'][0],
            type: tipoGrafico,
            data: dados,
            fill: false,
            borderColor: mixin.methods._obterCorParaItemGrafico(numeroLinha, linha['colunas'][0]),
            backgroundColor: mixin.methods._obterCorParaItemGrafico(numeroLinha, linha['colunas'][0]),
            order: 1,
            tension: 0,
            pointRadius: 4,
            borderWidth: 3
        });
    });
    return {
        type: tipoGrafico,
        data: { labels, datasets },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    id: "bar-y-axis1",
                    stacked: false,
                    ticks: {
                        beginAtZero: false,
                        callback: function (label, _index, _labels) {
                            return mixin.methods._formatarNumeroParaUnidadeArredondado(label, " ");
                        },
                        min: valorMinimo * (valorMinimo >= 0 ? 0.9 : 1.2)
                    },
                    scaleLabel: {
                        display: true,
                        labelString: analise.unidade || 'R$',
                        fontColor: '#666',
                        fontSize: 20
                    },
                }]
            },
            title: {
                display: true,
                text: nome || '',
                fontSize: 20
            },
            legend: {
                position: 'bottom'
            },
            tooltips: {
                position: 'average',
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function (tooltipItem) {
                        const valor = mixin.methods._formatarNumeroParaUnidade(parseFloat(tooltipItem.value || 0).toFixed(analise.numCasasDecimais), analise.unidade);
                        return `${datasets[tooltipItem.datasetIndex]?.label}: ${valor}`;
                    }
                }
            }
        }
    };
}

function obterGraficoAnaliseParetoDeTabela(cabecalho, linhas, labels, datasets, analise) {
    cabecalho.forEach((item, numeroColuna) => {
        let dados = [];
        const eixoPrimario = !item.includes('%');
        const indiceCor = (eixoPrimario ? numeroColuna : numeroColuna - 1) - 1;
        const tipoGrafico = !!eixoPrimario ? 'bar' : 'line';
        linhas.forEach((linha) => {
            if (numeroColuna == 0) {
                labels.push(linha.colunas[0]);
            } else if (!item.includes('Total')) {
                let valor = mixin.methods._formatarStringParaNumero(linha.colunas[numeroColuna].replace('%', '').trim());
                dados.push(valor);
            }
        });
        if (!item.includes('Total') && numeroColuna > 0) {
            datasets.push({
                label: item,
                type: tipoGrafico,
                data: dados,
                fill: false,
                backgroundColor: mixin.methods._obterCorParaItemGrafico(indiceCor, item),
                borderColor: tipoGrafico === 'bar' ? null : mixin.methods._obterCorParaItemGrafico(indiceCor, item),
                order: item.includes('%') ? 1 : 0,
                tension: 0,
                pointRadius: 4,
                borderWidth: 1,
                yAxisID: !!eixoPrimario ? 'eixoY-barra' : 'eixoY-linha'
            });
        }
    });

    return {
        type: "bar",
        data: { labels, datasets },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
                display: true,
                text: analise.nome || '',
                fontSize: 20
            },
            scales: {
                xAxes: [
                    {
                        id: "bar-x-axis2",
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: '',
                            fontColor: '#666',
                            fontSize: 20
                        },
                    },
                ],
                yAxes: [
                    {
                        id: "eixoY-barra",
                        position: 'left',
                        color: '#666',
                        stacked: false,
                        ticks: {
                            beginAtZero: true,
                            callback: function (label, _index, _labels) {
                                return mixin.methods._formatarNumeroParaUnidadeArredondado(label, " ");
                            }
                        },
                        scaleLabel: {
                            display: true,
                            labelString: analise.unidade || 'R$',
                            fontColor: '#666',
                            fontSize: 20
                        },
                    },
                    {
                        id: "eixoY-linha",
                        position: 'right',
                        stacked: false,
                        ticks: {
                            beginAtZero: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '%',
                            fontColor: '#444',
                            fontSize: 20
                        },
                    }
                ]
            },
            legend: {
                position: 'bottom'
            },
            tooltips: {
                position: 'average',
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: !!analise.esconderPercentuais ? function (tooltipItem, data) {
                        return `${data.datasets[tooltipItem.datasetIndex]?.label}: 
                            ${mixin.methods._formatarNumeroParaUnidade(parseFloat(tooltipItem.value || 0).toFixed(analise.numCasasDecimais), analise.unidade)}`;
                    } : function (tooltipItem) {
                        let valorAbsoluto = tooltipItem.datasetIndex % 2 == 0;
                        let valor = parseFloat(tooltipItem.value || 0);

                        valor = mixin.methods._formatarNumeroParaUnidade(valor, (valorAbsoluto ? analise.unidade : "%"));
                        return `${datasets[tooltipItem.datasetIndex]?.label}: ${valor}`;
                    },
                }
            }
        }
    };
}

