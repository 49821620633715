const parametros = [
    {
        "nome": "entrada",
        "tipoBigQuery": "BOOLEAN",
        "descricao": "Fornecedor(false)/Cliente(true) =SE(entrada=\"C\";true;false)",
        "obrigatorio": true,
        "agrupador": false
    },
    {
        "nome": "quitado",
        "tipoBigQuery": "BOOLEAN",
        "descricao": "=SE(data_baixa;true;false)",
        "obrigatorio": true,
        "agrupador": false
    },
    {
        "nome": "data_referencia",
        "tipoBigQuery": "BOOLEAN",
        "descricao": "=SE(ÉCÉL.VAZIA(data_baixa);data;data_baixa)",
        "obrigatorio": true,
        "agrupador": false
    },
    {
        "nome": "mes_referencia",
        "tipoBigQuery": "BOOLEAN",
        "descricao": "=MÊS(data_referencia)&\"/\"&ANO(data_referencia)",
        "obrigatorio": true,
        "agrupador": false
    },
    {
        "nome": "ano_referencia",
        "tipoBigQuery": "BOOLEAN",
        "descricao": "=ANO(data_referencia)",
        "obrigatorio": true,
        "agrupador": false
    },
    {
        "nome": "data",
        "tipoBigQuery": "DATE",
        "descricao": "Campo de data de vencimento do lançamento no formato dd/mm/yyyy",
        "obrigatorio": true,
        "agrupador": false
    },
    {
        "nome": "data_baixa",
        "tipoBigQuery": "DATE",
        "descricao": "Campo de data da baixa no formato dd/mm/yyyy",
        "obrigatorio": true,
        "agrupador": false
    },
    {
        "nome": "valor",
        "tipoBigQuery": "FLOAT",
        "descricao": "Valor do lançamento.",
        "obrigatorio": true,
        "agrupador": false
    },
    {
        "nome": "cliente_fornecedor",
        "tipoBigQuery": "STRING",
        "descricao": "Nome do cliente ou fornecedor do lançamento.",
        "obrigatorio": false,
        "agrupador": true,
        "label": "Nome"
    },
    {
        "nome": "parcelas",
        "tipoBigQuery": "STRING",
        "descricao": "Parcelas do lançamento.",
        "obrigatorio": false,
        "agrupador": true,
        "label": "Parcelas"
    },
    {
        "nome": "produto",
        "tipoBigQuery": "STRING",
        "descricao": "Produtos",
        "obrigatorio": false,
        "agrupador": true,
        "label": "Produtos"
    },
    {
        "nome": "unidade_medicao",
        "tipoBigQuery": "STRING",
        "descricao": "Unidades Medição",
        "obrigatorio": false,
        "agrupador": true,
        "label": "Unidades Medição"
    },
    {
        "nome": "banco",
        "tipoBigQuery": "STRING",
        "descricao": "Bancos",
        "obrigatorio": false,
        "agrupador": true,
        "label": "Bancos"
    },
    {
        "nome": "conta",
        "tipoBigQuery": "STRING",
        "descricao": "Contas",
        "obrigatorio": false,
        "agrupador": true,
        "label": "Contas"
    },
    {
        "nome": "modalidade",
        "tipoBigQuery": "STRING",
        "descricao": "Modalidades",
        "obrigatorio": false,
        "agrupador": true,
        "label": "Modalidades"
    },
    {
        "nome": "grupo",
        "tipoBigQuery": "STRING",
        "descricao": "Agrupamento no qual o lançamento faz parte.",
        "obrigatorio": false,
        "agrupador": true,
        "label": "Grupos"
    },
    {
        "nome": "plano_contas",
        "tipoBigQuery": "STRING",
        "descricao": "Plano de Contas",
        "obrigatorio": false,
        "agrupador": true,
        "label": "Plano de Contas"
    },
    {
        "nome": "centro_custos",
        "tipoBigQuery": "STRING",
        "descricao": "Centros de Custos",
        "obrigatorio": false,
        "agrupador": true,
        "label": "Centros de Custos"
    },
    {
        "nome": "status",
        "tipoBigQuery": "STRING",
        "descricao": "Status",
        "obrigatorio": false,
        "agrupador": true,
        "label": "Status"
    },
]

export default {
    parametros,
    obterAgrupadores() {
        return parametros.filter(p => {
            return !!p.agrupador;
        }).sort((a, b) => (a.nome > b.nome) ? 1 : -1)
    },
    obterObrigatorios() {
        return parametros.filter(p => {
            return !!p.obrigatorio;
        }).sort((a, b) => (a.nome > b.nome) ? 1 : -1)
    },
    obterPossiveisCasasDecimais() {
        return [0, 1, 2, 3, 4];
    }
}