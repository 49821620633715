const _formatarNumeroParaUnidadeArredondado = function (x, unidade) {
  x = Math.round(x).toString().replace('.', ',');
  x = x.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  return `${x} ${unidade || 'R$'}`;
};
const _formatarNumeroParaUnidade = function (x, unidade) {
  x = x.toString().replace('.', ',');
  x = x.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return `${x} ${unidade || 'R$'}`;
};
const _formatarStringParaNumero = function (x) {
  x = x.replace(/\./g, "").replace(',', '.');
  x = parseFloat(x);
  return x;
};
const _formatarPercentual = function (x) {
  x = x.toFixed(2).toString().replace('.', ',');
  x = x.split(',');
  x[0] = x[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  return `${x.join(',')}%`;
}
const _obterAnoMesIso = function (ano, mes) {
  return `${ano}-${mes >= 10 ? mes : '0' + mes}`;
};
const _obterDataFormatoBrasil = function (dataIso) {
  if (!dataIso) return null

  const [year, month, day] = dataIso.split('-')
  if (!day) return `${month}/${year}`;
  return `${day}/${month}/${year}`;
};
const _obterDataIso = function (date) {
  if (!date) return null;
  if (!date.includes("-")) {
    date = new Date(date).toISOString();
    date = date.split("T")[0];
  }
  return date;
}
const _obterDataFormatoBrasilDeUtcString = function (dataUtc, preencherHora = false) {
  if (!dataUtc) return null

  if (!!preencherHora) {
    dataUtc = parseInt(dataUtc) - 10800000;
  }

  let data = new Date(dataUtc).toISOString();

  const [year, month, day] = data.split('T')[0].split('-');
  let dataFormatada = `${day}/${month}/${year}`;

  if (!!preencherHora && data.split('T')[1]) {
    const [hora, minutos] = data.split('T')[1].split(':');
    dataFormatada = `${dataFormatada} ${hora}:${minutos}`;
  }

  return dataFormatada;
};
const _obterNomeMesDoNumero = function (indice, reduced = false) {
  let month = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ][indice - 1];
  if (reduced) {
    return month.slice(0, 3);
  }
  return month;
}
const _obterMesAnoFormatadosDoIso = function (dataIso) {
  if (dataIso && typeof dataIso === 'string') {
    let [year, month] = dataIso.split('-');
    if (!!dataIso.length) {
      return `${_obterNomeMesDoNumero(parseInt(month), true)}/${year.slice(2, 4)}`;
    }
  }
  throw new Error("Data inválida");
}
const _obterObjetoMesDoIso = function (dataIso) {
  if (typeof dataIso === 'string') {
    dataIso = dataIso.split('-');
    if (!!dataIso.length) {
      return {
        numeroMes: parseInt(dataIso[1]),
        nomeMes: _obterNomeMesDoNumero(parseInt(dataIso[1])),
        ano: parseInt(dataIso[0])
      }
    }
  }
  throw new Error("Data inválida em formato ISO");
}
const _converterListaObjetosMesDeDatasIso = function (listaDatasIso) {
  if (!!listaDatasIso && !!listaDatasIso.length) {
    let arr = [];
    listaDatasIso.forEach(data => {
      arr.push(_obterObjetoMesDoIso(data));
    });
    return arr;
  }
  throw new Error("Lista inválida de datas");
}
const _converterArquivoBase64 = async function (file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();

    reader.onloadend = function () {
      resolve(reader.result);
    }

    if (file) {
      reader.readAsDataURL(file);
    } else {
      reject();
    }
  });
}
const _obterDiferencaDeDias = function (dataInicio, dataFim = null) {
  dataFim = !dataFim ? new Date() : new Date(dataFim);
  return 1 - Math.ceil((dataFim.getTime() - new Date(dataInicio).getTime()) / (1000 * 60 * 60 * 24));
}
const _obterCorParaItemGrafico = function (indiceCor, label) {

  if (label) {
    if (label === 'Entrada') return '#3cb44b';
    if (label === 'Saída') return '#e6194B';
  }

  const coresGrafico = [
    "#0000FF", // Azul
    "#FFA500", // Laranja
    "#4B0082", // Índigo
    "#FFC0CB", // Rosa
    "#800080", // Roxo
    "#00FFFF", // Ciano
    "#F08080", // Coral Claro
    "#8B4513", // Marrom
    "#7B68EE", // Roxo Médio
    "#DAA520", // Dourado
    "#808000", // Oliva
    "#FFD700", // Ouro
    "#20B2AA", // Aquamarine Claro
    "#9370DB", // Roxo Médio Pastel
    "#1E90FF", // Azul Dodger
    "#FF69B4", // Rosa Quente
    "#A0522D", // Sienna
    "#B0C4DE", // Azul Aço Claro
    "#2E8B57", // Verde Mar
    "#4682B4", // Azul Aço
    "#FF4500", // Laranja Vermelho
    "#BDB76B", // Cáqui Escuro
    "#6A5ACD", // Azul Ardósia
    "#008B8B", // Verde-azulado Escuro
    "#9ACD32", // Amarelo-Verde
    "#BA55D3", // Orquídea Médio
    "#ADFF2F", // Verde Amarelado
    "#EE82EE", // Violeta
    "#87CEFA", // Azul Céu Claro
    "#FFDAB9", // Pêssego
  ];

  return (indiceCor && indiceCor > 0) ? coresGrafico[indiceCor % 30] : coresGrafico[0];
}
const _obterIndiceCorGraficoMisto = function (num) {
  const proxPar = num % 2 === 0 ? num : num - 1;

  if (proxPar > 0) {
    return proxPar / 2;
  }

  return proxPar;
}

const _obterStringAleatoria = function () {
  return Math.random().toString(36).slice(-8);
}

export default {
  methods: {
    _formatarNumeroParaUnidadeArredondado,
    _formatarNumeroParaUnidade,
    _obterAnoMesIso,
    _obterMesAnoFormatadosDoIso,
    _obterNomeMesDoNumero,
    _obterObjetoMesDoIso,
    _converterListaObjetosMesDeDatasIso,
    _formatarPercentual,
    _obterDataFormatoBrasil,
    _converterArquivoBase64,
    _obterDataFormatoBrasilDeUtcString,
    _obterDiferencaDeDias,
    _obterCorParaItemGrafico,
    _obterIndiceCorGraficoMisto,
    _obterDataIso,
    _formatarStringParaNumero,
    _obterStringAleatoria,
  },
};
