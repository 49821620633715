<template>
  <v-app>
    <Alert></Alert>
    <Loading></Loading>
    <input type="text" class="copy-to-clipboard" id="copy-to-clipboard" />
    <v-app-bar
      v-if="$route.name !== 'login' && !isInIframe"
      color="primary"
      dark
      dense
      app
      fixed
    >
      <v-responsive
        @click="redirect('home')"
        class="d-flex align-start"
        style="max-width: 100px; cursor: pointer"
      >
        <v-img
          contain
          height="24px"
          :src="require('./assets/lunis-logo.svg')"
        ></v-img>
      </v-responsive>

      <v-spacer></v-spacer>

      <v-tooltip
        v-for="rota in rotasFiltradas"
        :key="rota.name"
        :id="rota.name"
        bottom
      >
        <template v-if="rota.meta.icon" v-slot:activator="{ on, attrs }">
          <v-btn icon @click="redirect(rota.name)" v-bind="attrs" v-on="on">
            <v-icon>{{ rota.meta.icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ rota.meta.nome }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="logout()" v-bind="attrs" v-on="on">
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        <span>Sair</span>
      </v-tooltip>

      <template v-if="$route.name === 'home'" v-slot:extension>
        <v-tabs v-model="aba" align-with-title>
          <v-tab>Resumo</v-tab>
          <v-tab>Feed</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<style>
.copy-to-clipboard {
  display: none !important;
}
.app-content {
  padding-left: 40px !important;
  padding-top: 16px !important;
}
.app-font-color {
  color: #125e55 !important;
}
.app-tab-component {
  height: 100%;
}
.app-tab-component-padding {
  height: 100%;
  padding-top: 80px;
}
.v-input__prepend-inner {
  margin-top: 4px !important;
}
.card-text-disabled {
  color: #9eabb5;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.card-title-disabled {
  color: #9eabb5;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}
.altura-maxima {
  height: 90%;
}
.menu {
  z-index: 2;
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Alert from "@/components/Alert.vue";
import Loading from "@/components/Loading.vue";
import pt from "vuetify/es5/locale/pt";
import routes from "@/router/routes.js";
export default {
  name: "App",
  components: {
    Alert,
    Loading,
  },

  computed: {
    ...mapGetters(["abaHome"]),
    ...mapGetters("autenticacao", ["claimsUsuarioLogado"]),
    ...mapGetters("analises", ["analisesExternas"]),
    rotasFiltradas() {
      return this.routes.filter((r) => {
        return (
          !r.meta.public &&
          !!this.claimsUsuarioLogado &&
          (r.meta.papeis === undefined || (
            Array.isArray(r.meta.papeis) && (
              r.meta.papeis.length === 0 || 
              r.meta.papeis.includes(this.claimsUsuarioLogado.papel)
            )
          ))
        );
      });
    },
    isInIframe() {
      return window.self !== window.top;
    },
    aba: {
      get() {
        return this.abaHome;
      },
      set(val) {
        this.setAbaHome(val);
      },
    },
  },

  data() {
    return {
      drawer: false,
      routes,
    };
  },

  methods: {
    ...mapActions(["redirect"]),
    ...mapActions("autenticacao", ["logout"]),
    ...mapMutations(["setAbaHome"]),
    menuTransition() {
      this.drawer = !this.drawer;
    },
  },

  created() {
    this.$vuetify.lang.current = "pt";
    this.$vuetify.lang.locales = {
      pt: pt,
    };
  },
};
</script>
