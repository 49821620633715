<template>
  <v-bottom-sheet v-model="dialog">
    <template #activator="{ on: onMenu }">
      <v-tooltip top>
        <template #activator="{ on: onTooltip }">
          <v-btn
            fab
            right
            bottom
            fixed
            color="primary"
            dark
            v-on="{ ...onMenu, ...onTooltip }"
          >
            <v-icon>mdi-comment-text-outline</v-icon>
          </v-btn>
        </template>

        <span>Comentários</span>
      </v-tooltip>
    </template>
    <v-card tile class="pa-5">
      <v-card-text class="ma-0 pa-0 mt-5">
        Adicione suas notas visuais e clique em salvar:
      </v-card-text>
      <v-textarea
        counter="1000"
        auto-grow
        outlined
        v-model="textoNotas"
      ></v-textarea>
      <v-card-text class="ma-0 pa-0 mt-5">
        Adicione suas anotações ocultas e clique em salvar:
      </v-card-text>
      <v-textarea
        counter="1000"
        auto-grow
        outlined
        v-model="textoAnotacoes"
      ></v-textarea>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialog = false">Fechar</v-btn>
        <v-btn @click="salvarAnotacoesAnaliseAsync" color="primary">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Anotacoes",
  props: {
    anotacoes: { type: String, default: "", required: false },
    notas: { type: String, default: "", required: false },
    identificadorAnalise: { type: String, default: "", required: true },
  },
  methods: {
    ...mapActions(["salvarAnotacoesAnalise"]),
    salvarAnotacoesAnaliseAsync: async function () {
      const {
        identificadorAnalise,
        salvarAnotacoesAnalise,
        textoAnotacoes,
        textoNotas,
      } = this;
      if (
        (textoAnotacoes || "").length > 1000 ||
        (textoNotas || "").length > 1000
      ) {
        return alert("Digite no máximo 1000 caracteres.");
      }
      this.dialog = false;
      const hasSaved = await salvarAnotacoesAnalise({
        id: identificadorAnalise,
        anotacoes: textoAnotacoes || "",
        notas: textoNotas || "",
      });
      if (hasSaved) {
        this.$emit("changed", { anotacoes: textoAnotacoes, notas: textoNotas });
      }
    },
  },
  computed: {},
  watch: {
    notas(val) {
      this.textoNotas = val;
    },
    anotacoes(val) {
      this.textoAnotacoes = val;
    },
  },
  data() {
    return {
      textoNotas: "",
      textoAnotacoes: "",
      dialog: false,
    };
  },
};
</script>
