import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from './plugins/vuetify';
import mixin from '@/mixin/index.js';
import routes from '@/router/routes.js';
import firebase from "firebase/compat/app";

import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics"

import SeletorPeriodo from '@/components/SeletorPeriodo.vue';
import SeletorGenerico from '@/components/SeletorGenerico.vue';
import Tabela from '@/components/Tabela.vue';
import MixedChart from "@/components/MixedChart.vue";
import CabecalhoPadrao from '@/components/CabecalhoPadrao.vue';

import ResultadosIndisponiveis from "@/components/ResultadosIndisponiveis.vue";

Vue.component('seletor-periodo', SeletorPeriodo);
Vue.component('seletor-generico', SeletorGenerico);
Vue.component('tabela', Tabela);
Vue.component('grafico-misto', MixedChart);
Vue.component('resultados-indisponiveis', ResultadosIndisponiveis);
Vue.component('cabecalho-padrao', CabecalhoPadrao);

window.showError = function (text) {
  store.dispatch("showError", text);
}
window.showSuccess = function (text) {
  store.dispatch("showSuccess", text);
}

Vue.config.productionTip = false;
Vue.mixin(mixin);
routes.filter(e => !e.meta.public).forEach(r => {
  Vue.component(r.name, r.component);
});

const { firebaseConfig } = require("../config.json");
firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

export { analytics }