import autenticacaoServices from '@/services/autenticacao.services.js';
import mixin from '@/mixin/index.js';
import firebase from "firebase/compat/app";
import {
    getAuth,
    onAuthStateChanged,
    signOut,
    sendPasswordResetEmail
} from "firebase/auth";

const { organizacoes } = require("../../../../config.json");
const root = { root: true };

export default {
    obterUsuarios: async function ({ commit }) {
        commit("setLoading", true, root);
        try {
            const usuarios = await autenticacaoServices.consultarUsuarios();
            commit("setUsuarios", usuarios);
        } catch (err) {
            window.showError("Erro ao obter usuários.");
        } finally {
            commit("setLoading", false, root);
        }
    },

    verificarOrganizacaoAcesso: async function ({ commit }, { claims }) {
        const hostname = window.location.hostname;
        const parts = hostname.split('.');
        let organizacao = parts[0].replace(/-/g, "_");
        if (organizacao === 'localhost') {
            for (const key in organizacoes) {
                if (organizacoes.hasOwnProperty(key) && organizacoes[key] == 3) {
                    organizacao = key.replace(/-/g, "_");
                    break;
                }
            }
        } else if (organizacao === 'grupo_xr' || organizacao === 'grupoxcr') {
            organizacao = 'casa_dos_contos'
        }
        if (typeof organizacoes[organizacao] !== 'number' || !claims.organizacoes
            || !claims.organizacoes.includes(organizacao)) {
            if (!claims.papel || claims.papel == 3) {
                throw new Error('Organização inválida.');
            }
        }
        window.localStorage.setItem('lunis-organizacao', organizacao);
    },

    exibirListaOrganizacoes: async function ({ dispatch }) {
        // const organizacao = window.localStorage.getItem('lunis-organizacao');
        let atualizarListas = true;
        // if (organizacoes[organizacao] === 2) {
        //     const inputOptions = {};
        //     for (const key in organizacoes) {
        //         if (organizacoes.hasOwnProperty(key) && organizacoes[key] != 2) {
        //             inputOptions[key] = key;
        //         }
        //     }
        //     const { value } = await Swal.fire({
        //         title: 'Selecione uma organização',
        //         input: 'select',
        //         inputOptions: inputOptions,
        //         inputPlaceholder: 'Selecione',
        //         showCancelButton: true,
        //         cancelButtonText: 'Cancelar',
        //         confirmButtonText: 'Selecionar',
        //         customClass: {
        //             confirmButton: 'sweet-alert-confirm-button-class',
        //             cancelButton: 'sweet-alert-cancel-button-class'
        //         },
        //         reverseButtons: true,
        //     });
        //     if (value) {
        //         atualizarListas = true;
        //         window.localStorage.setItem('lunis-organizacao', value);
        //     }
        // }
        dispatch('analises/obterListaDeAnalises', atualizarListas, root);
    },

    verificarUsuarioAutenticado({ rootGetters, commit }, next) {
        if (!rootGetters['tokenUsuario']) {
            commit("setLoading", true, root);
            onAuthStateChanged(getAuth(), async user => {
                commit("setLoading", false, root);
                if (!user) {
                    window.showError('Sessão Expirada! Favor realizar login novamente.');
                    return next({ name: 'login' });
                }
                const token = await user.getIdToken();
                commit("setTokenUsuario", token, root);
                const { claims } = await user.getIdTokenResult(true);
                commit('autenticacao/setClaimsUsuarioLogado', claims, root);
                return next();
            });
        } else {
            return next();
        }
    },

    logout: async function ({ dispatch }) {
        try {
            await signOut(getAuth());
            dispatch('redirect', 'login', root);
        } catch (err) {
            console.log(err)
        }
    },

    obterClaimsUsuarioSelecionado: async function ({ commit }, uid) {
        commit("setLoadingButton", true, root);
        try {
            const resposta = await autenticacaoServices.obterClaimsUsuarioSelecionado(uid);
            commit("setClaimsUsuarioSelecionado", resposta);
        } catch (err) {
            commit("setClaimsUsuarioSelecionado", {});
            window.showError("Erro ao obter claims usuários.");
        } finally {
            commit("setLoadingButton", false, root);
        }
    },

    obterAreasPerfis: async function ({ state, commit, dispatch, rootGetters }) {
        commit("setLoadingButton", true, root);

        try {
            if (!rootGetters['areasPerfis']) {
                await dispatch('obterAreasEPerfis', null, root);
            }
            state.areasPerfis = rootGetters['areasPerfis'];
        } catch (err) {
            window.showError("Erro ao obter areas e perfis.");
        } finally {
            commit("setLoadingButton", false, root);
        }
    },

    salvarClaims: async function ({ commit }, { uid, perfil, areas, papel, organizacoes }) {
        commit("setLoadingButton", true, root);
        try {
            let body = {
                "claims": {
                    perfil,
                    areas,
                    papel,
                    organizacoes
                }
            };
            await autenticacaoServices.atualizarClaimsUsuario(uid, body);
        } catch (err) {
            window.showError("Erro ao salvar claims.");
        } finally {
            commit("setLoadingButton", false, root);
        }
    },

    criarNovoUsuario: async function ({ commit }, form) {
        commit("setLoadingButton", true, root);
        try {
            const body = {
                "claims": {
                    "perfil": form.perfil,
                    "areas": form.areas,
                    "papel": form.papel,
                    "organizacoes": form.organizacoes,
                },
                email: form.email
            };
            await autenticacaoServices.criarNovoUsuario(body);
            await sendPasswordResetEmail(getAuth(), form.email);
            window.showSuccess("Usuário criado com sucesso!");
        } catch (err) {
            window.showError("Erro ao criar novo usuário.");
        } finally {
            commit("setLoadingButton", false, root);
        }
    },

    solicitarEmailTrocaSenha: async function ({ commit }, email) {
        commit("setLoadingButton", true, root);

        await firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                window.showSuccess("Um email para a troca de senha foi enviado com sucesso!");
            })
            .catch((erro) => {
                erro.code === "auth/user-not-found" ?
                    window.showError("Não foi encontrado um usuário cadastrado com esse email.")
                    : window.showError("Falha enviar email para troca de senha, tente novamente mais tarde.");
            }).finally(() => {
                commit("setLoadingButton", false, root);
            });
    },

    deletarUsuarioAtivo: async function ({ commit }, uid) {
        commit("setLoading", true, root);

        try {
            await autenticacaoServices.deletarUsuario(uid);

            window.showSuccess("Usuário deletado com sucesso!");
        } catch (err) {
            window.showError("Falha ao deletar usuário.");
        } finally {
            commit("setLoading", false, root);
        }
    },

}