<template>
  <v-col cols="12" class="ma-0 pa-0">
    <div v-if="!!apresentacao" class="apresentacao-container secondary">
      <v-switch
        class="ma-0 pa-0 mx-2"
        dense
        v-model="dividirTela"
        hide-details
        :label="`Dividir Tela`"
        style="height: 20px"
      ></v-switch>
      <v-pagination
        v-model="page"
        dense
        :length="tamanhoApresentacao"
        circle
        color="primary"
      ></v-pagination>
    </div>
    <iframe
      title="apresentacao-frame"
      id="apresentacao-frame-1"
      :width="dividirTela ? `50%` : `100%`"
      height="1500px"
      scrolling="no"
      :src="iframeSrc1"
      class="apresentacao-frame"
    ></iframe>
    <iframe
      title="apresentacao-frame"
      v-if="dividirTela"
      id="apresentacao-frame"
      width="50%"
      height="1500px"
      scrolling="no"
      :src="iframeSrc2"
      class="apresentacao-frame"
    ></iframe>
  </v-col>
</template>

<style scoped>
.apresentacao-container {
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
}
.apresentacao-frame {
  padding-left: 8px;
  border: 0px;
  border-left: 1px solid #e0e0e0;
  box-sizing: border-box;
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Apresentacao",
  computed: {
    ...mapGetters("analises", ["apresentacao", "analisesExternas"]),
    tamanhoApresentacao() {
      return this.apresentacao?.analisesApresentacao
        ? (this.dividirTela ? this.apresentacao.analisesApresentacao.length -1 : this.apresentacao.analisesApresentacao.length) ?? 0
        : 0;
    },
    iframeSrc1() {
      let index =  this.dividirTela ? (this.page % this.apresentacao.analisesApresentacao.length) - 1 : this.page -1;    

      const analiseFrame1 = this.apresentacao.analisesApresentacao[index];
      let url = this.montarLink(analiseFrame1);
      return url;
    },
    iframeSrc2() {
      let index = this.dividirTela ? (this.page % this.apresentacao.analisesApresentacao.length) : this.page;
      console.log("index 2", index)

      const analiseFrame2 = this.apresentacao.analisesApresentacao[index];

      if(!!analiseFrame2) {
        let url = this.montarLink(analiseFrame2);
        return url;
      }

      return;
    },
  },
  methods: {
    ...mapMutations("analises", ["setApresentacao"]),
    montarLink(analise) {
      if ([6].includes(analise.tipo)) {
        return `/analises/apresentacoes/${analise.id}`;
      } else if ([1, 2].includes(analise.tipo) || !analise.tipo) {
        return `/analises?id=${analise.id}`;
      } else {
        let indiceAnaliseExterna;
        this.analisesExternas.forEach((analiseExterna, indice) => {
          if (analise.nome === analiseExterna.nome) {
            indiceAnaliseExterna = indice;
          }
        });
        return `/analises/externas?indice=${indiceAnaliseExterna}`;
      }
    },
  },
  watch: {
    dividirTela(val) {
      this.setApresentacao({
        ...this.apresentacao,
        dividirTela: val,
      });
    },
  },
  data() {
    return {
      page: 1,
      dividirTela: false,
    };
  },
  mounted() {},
};
</script>