import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router/routes.js";
import store from "@/store/index.js"
import useAnalytics from "../services/analytics.service";

Vue.use(VueRouter);

const { logarEvento } = useAnalytics();

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, _from, next) => {
  if (!to.name) {
    return next({ name: 'home' });
  } else if (to.name !== 'login') {
    window.sessionStorage.setItem('lunis-next-route', to.fullPath);
  } else {
    return next();
  }
  store.dispatch("autenticacao/verificarUsuarioAutenticado", next, { root: true });
  logarEvento('page_view', { page_path: to.fullPath, from_page: _from.path });
});

export default router;
