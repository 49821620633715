import Login from "../views/autenticacao/Login.vue";
import Home from "../views/Home.vue";
import Analises from "../views/Analises.vue";
import RegistroUsuarios from "../views/autenticacao/RegistroUsuarios.vue";
import GestaoDados from "../views/GestaoDados.vue";
import AnalisesExternas from "../views/AnalisesExternas.vue";

export default Object.freeze([
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            nome: "Login",
            public: true
        }
    },
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            nome: "Início",
            public: false,
            icon: "mdi-home"
        }
    },
    {
        path: "/analises",
        name: "analises",
        component: Analises,
        meta: {
            nome: "Análises Internas",
            public: false,
            icon: "mdi-chart-line"
        }
    },
    {
        path: "/analises/externas",
        name: "analises-externas",
        component: AnalisesExternas,
        meta: {
            nome: "Análises Integradas",
            public: false,
            icon: "mdi-search-web",
            analisesExternas: true
        }
    },
    {
        path: "/analises/apresentacoes/:idApresentacao",
        name: "apresentacoes",
        component: Analises,
        meta: {
            nome: "Apresentações",
            public: false
        }
    },
    {
        path: "/gestao-dados",
        name: "gestao-dados",
        component: GestaoDados,
        meta: {
            nome: "Gestão de Dados",
            public: false,
            icon: "mdi-table-large-plus",
            papeis: [1, 4],
        }
    },
    {
        path: "/registroUsuarios",
        name: "registroUsuarios",
        component: RegistroUsuarios,
        meta: {
            nome: "Contas de Usuários",
            public: false,
            icon: "mdi-account-plus",
            papeis: [1],
        },
    },
]);