<template>
  <v-row class="app-content ma-0 pr-4" justify="center">
    <v-col cols="12" class="ma-0 pa-0">
      <cabecalho-padrao></cabecalho-padrao>
    </v-col>

    <v-col cols="12" class="ml-5 pa-0">
      <v-data-table
        :headers="headers"
        :items="dadosUsuario"
        sort-by="email"
        class="elevation-1"
      >
        <template v-slot:[`item.nomesOrganizacoes`]="{ item }">
          <v-chip
            outlined
            color="primary"
            class="my-1 mr-1 ma-0"
            dark
            v-for="org in getOrganizacoes(item)"
            :key="`nomesOrganizacoes${org}`"
          >
            {{ org }}
          </v-chip>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>USUÁRIOS</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialogNovoUsuario" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Novo Usuário
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-form v-model="formValido" ref="formularioLogin">
                          <v-text-field
                            v-model="form.email"
                            label="Email *"
                            required
                            :rules="regrasEmail"
                          ></v-text-field>
                          <v-select
                            label="Perfil"
                            item-text="nome"
                            return-object
                            :items="perfis"
                            v-model="form.perfil"
                            :rules="regraNaoNulo"
                          >
                          </v-select>
                          <v-select
                            label="Áreas"
                            return-object
                            item-text="nome"
                            item-value="id"
                            :items="areas"
                            chips
                            multiple
                            v-model="form.areas"
                            clearable
                            :rules="regraNaoNulo"
                          ></v-select>
                          <v-select
                            label="Papel"
                            item-text="label"
                            item-value="id"
                            :items="papeis"
                            v-model="form.papel"
                            :rules="regraNaoNulo"
                          >
                          </v-select>
                          <v-select
                            label="Organizações"
                            :items="organizacoesBase"
                            chips
                            multiple
                            v-model="form.organizacoes"
                            clearable
                            :rules="regraSelectMultiploNaoNulo"
                          ></v-select>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="fecharModalNovoUsuario"
                    class="primary--text"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="registrarUsuario"
                    :loading="isLoadingButton"
                    :disabled="!formValido"
                    >Registrar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPermissoes" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Gerenciar Permissões</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-text-field
                          clearable
                          v-model="usuarioSelecionado.email"
                          label="Email"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-select
                          label="Perfil"
                          item-text="nome"
                          return-object
                          :items="perfis"
                          v-model="perfilSelecionado"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="mt-0 pa-0">
                        <v-select
                          label="Áreas"
                          return-object
                          item-text="nome"
                          item-value="id"
                          :items="areas"
                          chips
                          multiple
                          v-model="areasSelecionadas"
                          clearable
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-select
                          label="Papel"
                          item-text="label"
                          item-value="id"
                          :items="papeis"
                          v-model="papelSelecionado"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="mt-0 pa-0">
                        <v-select
                          label="Organizações"
                          :items="organizacoesBase"
                          chips
                          multiple
                          v-model="organizacoesSelecionadas"
                          clearable
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    class="primary--text"
                    @click="fecharModalPermissoes()"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="salvarClaimsDoUsuario"
                    :loading="isLoadingButton"
                    >Salvar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Deseja realmente deletar o usuário?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text class="primary--text" @click="fecharModalDelete"
                    >Cancelar</v-btn
                  >
                  <v-btn color="primary" @click="deletarUsuario">Sim</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editarPermissoes(item)">
            mdi-account-alert
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
      <v-row class="mt-5"> </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
const { organizacoes } = require("../../../config.json");

export default {
  name: "RegistroUsuarios",
  components: {},
  data() {
    return {
      form: {
        email: null,
        areas: null,
        perfil: null,
        organizacoes: null,
        papel: null,
      },
      formValido: true,
      dadosUsuario: [],
      dialogNovoUsuario: false,
      dialogPermissoes: false,
      dialogDelete: false,
      headers: [
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
        },
        { text: "Papel", value: "nomePapel", sortable: true },
        { text: "Organizacoes", value: "nomesOrganizacoes", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      usuarioSelecionado: {},
      areasSelecionadas: null,
      perfilSelecionado: {},
      papelSelecionado: {},
      organizacoesSelecionadas: null,
      defaultItem: {},
      usuarioDelecao: null,
      regrasEmail: [
        (v) => !!v || "Obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
      ],
      regraNaoNulo: [(v) => !!v || "Campo Obrigatório"],
      regraSelectMultiploNaoNulo: [(v) => v.length > 0 || "Campo Obrigatório"],
      papeis: [
        { label: "Administrador", id: 1 },
        { label: "Contabilidade", id: 2 },
        { label: "Cliente", id: 3 },
        { label: "Gestor", id: 4 },
      ],
    };
  },

  computed: {
    ...mapGetters(["isLoadingButton"]),
    ...mapGetters("autenticacao", [
      "usuarios",
      "areasPerfis",
      "claimsUsuarioSelecionado",
    ]),

    formTitle() {
      return "Novo Usuário";
    },

    areas: {
      get() {
        return this.areasPerfis?.areas;
      },
    },

    perfis: {
      get() {
        return this.areasPerfis?.perfis;
      },
    },

    organizacoesBase() {
      return Object.entries(organizacoes).map(([organizacao]) => organizacao);
    },
  },

  watch: {
    dialogNovoUsuario(val) {
      val || this.fecharModalNovoUsuario();
    },
    dialogDelete(val) {
      val || this.fecharModalDelete();
    },
    usuarios(val) {
      let usuarios = val.map((user) => {
        const { uid, email } = user;
        const { organizacoes, papel } = user.customClaims || {};
        let nomePapel =
          typeof papel === "number"
            ? this.papeis.find((p) => {
                return p.id == papel;
              }).label
            : null;
        return {
          uid,
          email,
          nomePapel,
          nomesOrganizacoes: organizacoes || [],
        };
      });

      this.dadosUsuario = usuarios;
    },
  },

  created() {
    this.obterUsuarios();
    this.obterAreasPerfis();
    this.limparFormUsuario();
  },

  methods: {
    ...mapMutations(["setLoading", "setLoadingButton"]),
    ...mapActions(["showError", "showSuccess"]),
    ...mapActions("autenticacao", [
      "obterUsuarios",
      "obterClaimsUsuarioSelecionado",
      "obterAreasPerfis",
      "salvarClaims",
      "criarNovoUsuario",
      "deletarUsuarioAtivo",
    ]),

    getOrganizacoes(item) {
      let arr = [];
      if (!!item.nomesOrganizacoes) {
        item.nomesOrganizacoes.forEach((org) => {
          arr.push(org);
        });
      }
      return arr.sort();
    },

    async registrarUsuario() {
      await this.criarNovoUsuario(this.form);
      this.fecharModalNovoUsuario();
      this.limparFormUsuario();
      await this.obterUsuarios();
    },

    async deletarUsuario() {
      this.dialogDelete = false;
      await this.deletarUsuarioAtivo(this.usuarioDelecao.uid);
      await this.obterUsuarios();
    },

    limparFormUsuario() {
      this.form.email = null;
      this.form.perfil = null;
      this.form.areas = null;
      this.form.papel = null;
      this.form.organizacoes = [];
    },

    salvarClaimsDoUsuario: async function () {
      await this.salvarClaims({
        uid: this.usuarioSelecionado?.uid,
        perfil: this.perfilSelecionado,
        areas: this.areasSelecionadas,
        papel: this.papelSelecionado || null,
        organizacoes: this.organizacoesSelecionadas || [],
      });
      this.fecharModalPermissoes();
      await this.obterUsuarios();
    },

    editarPermissoes: async function (usuario) {
      this.setLoading(true);
      this.usuarioSelecionado = Object.assign({}, usuario);
      await this.obterClaimsUsuarioSelecionado(usuario?.uid);
      this.areasSelecionadas = this.claimsUsuarioSelecionado?.areas;
      this.perfilSelecionado = this.claimsUsuarioSelecionado?.perfil;
      this.papelSelecionado = this.claimsUsuarioSelecionado.papel;
      this.organizacoesSelecionadas =
        this.claimsUsuarioSelecionado?.organizacoes;

      this.dialogPermissoes = true;
      this.setLoading(false);
    },

    deleteItem(item) {
      this.usuarioDelecao = Object.assign({}, item);
      this.dialogDelete = true;
    },

    fecharModalNovoUsuario() {
      this.dialogNovoUsuario = false;
      this.limparFormUsuario();
    },

    fecharModalDelete() {
      this.dialogDelete = false;
    },

    fecharModalPermissoes() {
      this.dialogPermissoes = false;
    },
  },
};
</script>
