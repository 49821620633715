<template>
  <v-row class="ma-0 pa-0" align="center">
    <v-menu
      v-model="modelSeletorPeriodo"
      :close-on-content-click="false"
      :nudge-width="200"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-select
          label="Período"
          prepend-icon="mdi-calendar"
          :menu-props="{ maxHeight: 0 }"
          v-bind="attrs"
          v-on="on"
          v-model="valorInput"
          :value="valorInput"
          :items="itensDoInput"
          hide-details
          hide-selected
        ></v-select>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="primary">mdi-calendar</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Período</v-list-item-title>
              <v-list-item-subtitle
                >Selecione o período da análise</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item class="" v-if="!tipoPeriodoFixo">
            <v-radio-group v-model="tipoPeriodo" column>
              <v-radio label="Diário" color="primary" :value="1"></v-radio>
              <v-radio label="Mensal" color="primary" :value="2"></v-radio>
              <v-radio label="Anual" color="primary" :value="3"></v-radio>
              <v-radio label="Indefinido" color="primary" :value="4"></v-radio>
            </v-radio-group>
          </v-list-item>

          <v-list-item>
            <v-row
              v-show="filtro.tipoPeriodo == 1"
              justify="start"
              align="start"
              class="ma-0 pa-0"
            >
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-menu
                  ref="menuDataInicio"
                  v-model="menuDataInicio"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="filtro.tipoPeriodo != 1"
                      v-model="dataInicio"
                      label="Data Início"
                      dense
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="dataInicioComponente = parseDate(dataInicio)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataInicioComponente"
                    no-title
                    @input="menuDataInicio = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6">
                <v-menu
                  ref="menuDataFim"
                  v-model="menuDataFim"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="filtro.tipoPeriodo != 1"
                      v-model="dataFim"
                      label="Data Fim"
                      persistent-hint
                      dense
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      @blur="dataFimComponente = parseDate(dataFim)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataFimComponente"
                    no-title
                    @input="menuDataFim = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row
              v-show="filtro.tipoPeriodo == 2"
              justify="start"
              align="start"
              class="ma-0 pa-0 mt-5"
            >
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="selecionados"
                width="290px"
                :disabled="filtro.tipoPeriodo != 2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-row class="ma-0 pa-0" align="center">
                      <v-card
                        class="ma-0 pa-2"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 100%"
                      >
                        <v-row class="ma-0 pa-0" align="center" justify="start">
                          <v-icon class="mt-0 mr-2" color="primary">
                            mdi-calendar
                          </v-icon>
                          <p
                            class="
                              primary--text
                              ma-0
                              pa-0
                              mr-5
                              font-weight-bold
                              text-uppercase
                            "
                          >
                            {{ "Meses" }}
                          </p>
                        </v-row>
                        <v-row
                          v-if="selecionados.length"
                          class="ma-0 pa-0 mt-2"
                          align="center"
                          justify="start"
                        >
                          <v-chip
                            close
                            @click:close="removerMesSelecionado(mesSelecionado)"
                            class="mr-1 my-1 ml-0"
                            v-for="mesSelecionado in selecionadosOrdenados"
                            :key="mesSelecionado"
                          >
                            {{ _obterMesAnoFormatadosDoIso(mesSelecionado) }}
                          </v-chip>
                        </v-row>
                      </v-card>
                    </v-row>
                  </v-col>
                </template>
                <v-date-picker
                  multiple
                  v-model="selecionados"
                  type="month"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="$refs.dialog.save(selecionados)"
                  >
                    Confirmar
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-row>
            <v-row v-show="filtro.tipoPeriodo == 3">
              <v-dialog
                v-model="dialog"
                scrollable
                :fullscreen="$vuetify.breakpoint.xsOnly"
                max-width="800px"
                transition="dialog-bottom-transition"
                :disabled="filtro.tipoPeriodo != 3"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-col cols="12">
                    <v-row class="ma-0 pa-0" align="center">
                      <v-card
                        class="ma-0 pa-2"
                        v-bind="attrs"
                        v-on="on"
                        style="width: 100%"
                      >
                        <v-row class="ma-0 pa-0" align="center" justify="start">
                          <v-icon
                            class="mt-0 mr-2"
                            :color="
                              filtro.tipoPeriodo == 3 ? 'primary' : '#aeaeae'
                            "
                            >mdi-calendar</v-icon
                          >
                          <p
                            class="
                              primary--text
                              ma-0
                              pa-0
                              mr-5
                              font-weight-bold
                              text-none
                            "
                          >
                            {{ "Anos" }}
                          </p>
                        </v-row>
                        <v-row
                          class="ma-0 pa-0 mt-2"
                          align="center"
                          justify="start"
                          v-if="indicesAnosSelecionadosFiltrados.length"
                        >
                          <v-chip
                            close
                            class="mr-1 my-1 ml-0"
                            @click:close="removerAnoSelecionado(filtro)"
                            v-for="filtro in indicesAnosSelecionadosFiltrados"
                            :key="filtro"
                          >
                            {{ listaAnos[filtro] }}
                          </v-chip>
                        </v-row>
                      </v-card>
                    </v-row>
                  </v-col>
                </template>
                <v-card min-height="500px">
                  <v-card-title class="ma-0 pa-0">
                    <v-toolbar dark color="primary">
                      <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>Filtros de anos</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn dark text @click="limparAnos()">
                          Limpar anos
                        </v-btn>
                      </v-toolbar-items>
                    </v-toolbar>
                  </v-card-title>
                  <v-card-text class="ma-0 pa-0">
                    <v-row justify="start" class="ma-0 px-4 pt-4 pb-0"> </v-row>
                    <v-row class="ma-0 px-4 pb-4">
                      <v-chip-group
                        :multiple="selecaoMultipla"
                        active-class="primary white--text"
                        column
                        v-model="indicesAnosSelecionados"
                      >
                        <v-chip v-for="filtro in listaAnos" :key="filtro">
                          {{ filtro }}
                        </v-chip>
                      </v-chip-group>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="mb-4">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dialog = false"
                      >Confirmar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="limparSeletor()" class="primary--text">
            Limpar
          </v-btn>
          <v-btn color="primary" @click="modelSeletorPeriodo = false">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-row>
</template>

<script>
export default {
  name: "SeletorPeriodo",
  props: {
    tipoPeriodoFixo: {
      type: Number,
      default: null,
      required: false,
    },
    selecaoMultipla: {
      type: Boolean,
      default: true,
      required: false,
    },
    triggerPeriodo: {
      type: Object,
      default: null,
      required: false,
    },
  },
  computed: {
    selecionados: {
      get() {
        return this.meses;
      },
      set(val) {
        this.meses =
          !this.selecaoMultipla && !!val.length ? [val[val.length - 1]] : val;
      },
    },
    selecionadosOrdenados() {
      return this.selecionados.sort();
    },
    indicesAnosSelecionadosFiltrados() {
      return typeof this.indicesAnosSelecionados == "object"
        ? this.indicesAnosSelecionados.sort()
        : [this.indicesAnosSelecionados];
    },
  },
  methods: {
    emitirValor() {
      if (
        this.filtro.tipoPeriodo === 1 &&
        !!this.dataInicio &&
        !!this.dataFim
      ) {
        this.valorInput = `${this.dataInicio} a ${this.dataFim}`;
        this.itensDoInput = [this.valorInput];
      } else if (this.filtro.tipoPeriodo === 2 && !!this.meses.length) {
        this.valorInput = "";
        this.selecionadosOrdenados.forEach((mes, indice) => {
          if (indice > 0) {
            this.valorInput += ", ";
          }
          this.valorInput += this._obterMesAnoFormatadosDoIso(mes);
        });
        this.itensDoInput = [this.valorInput];
      } else if (
        this.filtro.tipoPeriodo === 3 &&
        !!this.indicesAnosSelecionadosFiltrados.length
      ) {
        this.valorInput = "";
        this.indicesAnosSelecionadosFiltrados.forEach((ano, indice) => {
          if (indice > 0) {
            this.valorInput += ", ";
          }
          this.valorInput += this.listaAnos[ano];
        });
        this.itensDoInput = [this.valorInput];
      } else if (this.filtro.tipoPeriodo === 4) {
        this.valorInput = "Indefinido";
        this.itensDoInput = [this.valorInput];
      } else {
        this.valorInput = null;
        this.itensDoInput = [];
      }
      this.$emit("changed", this.filtro);
    },
    limparSeletor() {
      this.tipoPeriodo = null;
      this.meses = [];
      this.limparAnos();
      this.dataInicio = null;
      this.dataFim = null;
      this.modelSeletorPeriodo = false;
    },
    removerMesSelecionado(mesSelecionado) {
      this.meses = this.meses.filter((m) => {
        return mesSelecionado !== m;
      });
    },
    removerAnoSelecionado(index) {
      this.indicesAnosSelecionados = this.indicesAnosSelecionados.filter(
        (obj) => {
          return obj != index;
        }
      );
    },
    limparAnos() {
      this.indicesAnosSelecionados = [];
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    triggerPeriodo(val) {
      if (val) {
        this.tipoPeriodo = val.tipoPeriodo;
        this.meses = [];
        if (this.tipoPeriodo == 1) {
          this.dataInicioComponente = this._obterDataIso(val.dataInicio);
          this.dataFimComponente = this._obterDataIso(val.dataFim);
        }
        if (this.tipoPeriodo == 2) {
          val.meses.forEach((m) => {
            this.meses.push(`${m.ano}-${m.mes.toString().padStart(2, "0")}`);
          });
        }
        if (this.tipoPeriodo == 3) {
          val.anos.forEach((a) => {
            this.listaAnos.forEach((ano, index) => {
              if (ano == a) {
                this.indicesAnosSelecionados.push(index);
              }
            });
          });
        }
      }
    },
    tipoPeriodoFixo(val) {
      this.tipoPeriodo = this.tipoPeriodoFixo;
    },
    dataInicioComponente(val) {
      this.filtro.dataInicio = new Date(
        this.dataInicioComponente
      ).toUTCString();
      this.dataInicio = this._obterDataFormatoBrasil(this.dataInicioComponente);
      this.emitirValor();
    },
    dataFimComponente() {
      this.filtro.dataFim = new Date(this.dataFimComponente).toUTCString();
      this.dataFim = this._obterDataFormatoBrasil(this.dataFimComponente);
      this.emitirValor();
    },
    tipoPeriodo(val) {
      if (val !== 1) {
        this.dataInicio = null;
        this.dataFim = null;
      }
      if (val !== 2) {
        this.meses = [];
      }
      if (val !== 3) {
        this.limparAnos();
      }
      this.filtro.tipoPeriodo = this.tipoPeriodo;
      this.emitirValor();
    },
    meses() {
      this.filtro.meses = [];
      this.meses.forEach((m) => {
        this.filtro.meses.push({
          mes: parseInt(m.split("-")[1]),
          ano: parseInt(m.split("-")[0]),
        });
      });
      this.emitirValor();
    },
    indicesAnosSelecionados(indicesSelecionados) {
      if (!this.selecaoMultipla && typeof indicesSelecionados != "object") {
        indicesSelecionados = [indicesSelecionados];
      }
      let arr = [];
      indicesSelecionados.forEach((indiceSelecionado) => {
        arr.push(this.listaAnos[indiceSelecionado]);
      });
      this.filtro.anos = arr.sort();
      this.emitirValor();
    },
  },
  data() {
    return {
      itensDoInput: [],
      modal: false,
      dialog: false,
      menuDataInicio: false,
      menuDataFim: false,
      modelSeletorPeriodo: false,
      dataInicioComponente: "",
      dataFimComponente: null,
      dataInicio: "",
      valorInput: null,
      dataFim: "",
      tipoPeriodo: null,
      meses: [],
      listaAnos: [],
      indicesAnosSelecionados: [],
      filtro: {
        dataInicio: "",
        dataFim: "",
        tipoPeriodo: null,
        meses: [],
        anos: [],
      },
    };
  },
  created() {
    this.tipoPeriodo = this.tipoPeriodoFixo;
    let anoAtual = new Date().getFullYear();
    for (let ano = anoAtual - 5; ano <= anoAtual + 5; ano++) {
      this.listaAnos.push(ano);
    }
  },
};
</script>
