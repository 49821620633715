<template>
  <v-row justify="start" align="start">
    <v-dialog
      v-model="modal"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="800px"
      transition="dialog-bottom-transition"
      :disabled="!itensDisponiveis || itensDisponiveis.length == 0"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card
          outlined
          class="ma-0 pa-2"
          v-bind="attrs"
          v-on="on"
          style="width: 100%"
        >
          <v-row class="ma-0 pa-0" align="center" justify="start">
            <v-icon
              class="mr-2"
              :color="itensDisponiveis.length > 0 ? 'primary' : '#aeaeae'"
            >
              mdi-chart-box-outline
            </v-icon>
            <p
              :class="
                itensDisponiveis.length > 0
                  ? 'primary--text ma-0 pa-0 mr-5 font-weight-bold text-uppercase'
                  : 'grey--text ma-0 pa-0 mr-5 font-weight-bold text-uppercase'
              "
            >
              {{ nomeEntidade }}
            </p>
          </v-row>
          <v-row
            v-if="itensSelecionados.length"
            class="ma-0 pa-0 mt-2"
            align="center"
            justify="start"
          >
            <v-chip
              close
              @click:close="removerItemSelecionado(item)"
              class="mr-1 my-1 ml-0"
              v-for="item in itensSelecionados"
              :key="item"
            >
              {{ item }}
            </v-chip>
          </v-row>
        </v-card>
      </template>

      <v-card min-height="500px">
        <v-card-title class="ma-0 pa-0">
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="modal = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Filtros de {{ nomeEntidade }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="limparItens()">
                Limpar {{ nomeEntidade }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="ma-0 pa-0 pt-2">
          <v-row justify="start" class="ma-0 px-4 py-0">
            <p class="ma-0 pa-0">{{ nomeEntidade }}:</p>
          </v-row>
          <v-autocomplete
            class="mx-4"
            placeholder="Selecione"
            outlined
            multiple
            return-object
            hide-details
            hide-selected
            chips
            item-text="nome"
            :search-input.sync="valorBusca"
            :items="itensDisponiveisOrdenados"
            v-model="itensSelecionados"
            clearable
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="mb-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="modal = false">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "SeletorGenerico",
  props: {
    itensDisponiveis: { type: Array, default: [], required: false },
    triggerItensSelecionados: {
      type: [String, Array],
      default: function () {},
      required: false,
    },
    nomeEntidade: { type: String, default: "", required: true },
    limparCard: { type: Boolean, default: false, required: false },
  },
  methods: {
    emitirValor() {
      this.$emit("changed", this.itensSelecionados);
    },
    removerItemSelecionado(itemSelecionado) {
      this.itensSelecionados = this.itensSelecionados.filter((i) => {
        return itemSelecionado !== i;
      });
    },
    limparItens() {
      this.itensSelecionados = [];
    },
  },
  computed: {
    itensDisponiveisOrdenados() {
      return !!this.itensDisponiveis && !!this.itensDisponiveis.length
        ? this.itensDisponiveis.sort()
        : [];
    },
  },
  watch: {
    itensSelecionados() {
      this.emitirValor();
      this.valorBusca = "";
    },
    limparCard(limpar) {
      if (limpar) this.itensSelecionados = [];
    },
    triggerItensSelecionados(val) {
      if (val && val.length > 0) {
        this.itensSelecionados = val;
      }
    },
  },

  data() {
    return {
      modal: false,
      itensSelecionados: [],
      valorBusca: "",
    };
  },
};
</script>
