import services from "@/services/services.js";

export default {

    async obterDadosAnalisePorId(body) {
        return await services.postAsync(`/analises`, body);
    },

    async obterListaDeAnalises() {
        return await services.getAsync(`/analises`);
    },

    async obterAnalisePorId(idAnalise) {
        return await services.getAsync(`/analises/${idAnalise}`);
    },

    async perguntarLunisAi(idAnalise, pergunta, lancamentos) {
        return await services.postAsync(`/analises/${idAnalise}/ai`, {
            pergunta,
            lancamentos
        });;
    },

}