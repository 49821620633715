<template>
  <v-row class="ma-0 pa-0" align="center" justify="space-around">
    <v-col
      v-if="!!analisesPorAreas.length"
      class="ma-0 pa-0 pr-4"
      cols="12"
      sm="3"
    >
      <v-menu
        v-model="seletorAnaliseModel"
        :close-on-content-click="false"
        :nudge-width="200"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-select
            label="Análises"
            prepend-icon="mdi-database-search-outline"
            :menu-props="{ maxHeight: 0 }"
            v-bind="attrs"
            v-on="on"
            item-value="id"
            item-text="nome"
            v-model="idAnalise"
            :value="idAnalise"
            :disabled="$route.name === 'fluxo-financeiro'"
            :items="analises"
            hide-details
            hide-selected
          ></v-select>
        </template>

        <v-card class="pb-5">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="primary">mdi-database-search-outline</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Análises</v-list-item-title>
                <v-list-item-subtitle>
                  Selecione a análise desejada
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-select
                label="Área"
                item-text="nome"
                return-object
                hide-details
                hide-selected
                :disabled="$route.name === 'fluxo-financeiro'"
                :items="analisesPorAreas"
                v-model="area"
              >
              </v-select>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item>
              <v-select
                label="Tipo de Análise"
                return-object
                hide-details
                hide-selected
                :disabled="(($route.name === 'fluxo-financeiro') || !tipoAnaliseSelecionada)"
                v-model="tipoAnaliseSelecionada"
                :items="[
                      { id: 1, nome: 'Horizontal' },
                      { id: 2, nome: 'Pareto' },
                    ]"
                item-text="nome"
                item-value="id"
              >
              </v-select>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item>
              <v-autocomplete
                label="Análises"
                item-value="id"
                hide-details
                item-text="nome"
                :disabled="!area"
                :items="analises"
                v-model="idAnalise"
              ></v-autocomplete>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-col>
    <v-col class="ma-0 pa-0 pr-4" cols="12" sm="3">
      <seletor-periodo
        :triggerPeriodo="triggerPeriodo"
        @changed="
          (filtrosPeriodo) => {
            filtrosPeriodoCampo = filtrosPeriodo;
          }
        "
      ></seletor-periodo>
    </v-col>
    <v-col class="ma-0 pa-0 pr-4" cols="12" sm="3">
      <v-autocomplete
        label="Variável"
        item-value="nome"
        prepend-icon="mdi-function-variant"
        hide-details
        hide-selected
        item-text="label"
        :items="agrupadores"
        clearable
        v-model="filtroAnalise.agrupador"
      ></v-autocomplete>
    </v-col>
    <v-col class="ma-0 pa-0 px-4" cols="12" sm="3">
      <v-row class="ma-0 pa-0" justify="end">
        <v-btn
          medium
          :loading="isLoadingButton"
          color="primary"
          dark
          @click="filtrarPeriodo()"
        >
          <v-icon class="mt-0 mr-2" color="white"> mdi-magnify </v-icon>
          <span>Pesquisar</span>
        </v-btn>
      </v-row>
    </v-col>
    <v-col
      v-if="this.mostrarSeletorVarAnalisada"
      class="ma-0 pa-0 mt-3"
      cols="12"
    >
      <v-row class="ma-0 pa-0 px-4" justify="end">
        <compartilhar v-if="!dividirTela && !isInIframe"></compartilhar>

        <v-menu
          v-model="modelFiltrosGenericos"
          :close-on-content-click="false"
          :nudge-width="200"
        >
          <template v-slot:activator="{ on: onMenu }">
            <v-tooltip bottom>
              <template #activator="{ on: onTooltip }">
                <v-btn color="primary" icon v-on="{ ...onMenu, ...onTooltip }">
                  <v-icon color="primary">mdi-filter-outline</v-icon>
                </v-btn>
              </template>
              <span>Filtros</span>
            </v-tooltip>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon color="primary">mdi-filter-variant</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Filtros</v-list-item-title>
                  <v-list-item-subtitle
                    >Selecione filtros para modificar a
                    análise</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list class="pa-5">
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="filtroAnalise.filtrarEntrada"
                    :label="'Receita'"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="filtroAnalise.filtrarSaida"
                    :label="'Despesa'"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="filtroAnalise.filtrarQuitado"
                    :label="'Quitado'"
                  ></v-checkbox
                ></v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="filtroAnalise.filtrarPendente"
                    :label="'Pendente'"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-col
                v-if="filtroAnalise.agrupador"
                cols="12"
                sm="12"
                md="12"
                lg="12"
              >
                <seletor-generico
                  class="mb-4"
                  v-bind:itensDisponiveis="itensDistintos"
                  :triggerItensSelecionados="triggerItensSelecionados"
                  :nomeEntidade="filtroAnalise.agrupador"
                  @changed="
                    (itensSelecionados) => {
                      filtroAnalise.itensDistintosSelecionados =
                        itensSelecionados;
                    }
                  "
                ></seletor-generico>
              </v-col>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-select
                    :items="labelsFiltro"
                    label="Ordenar por"
                    outlined
                    dense
                    v-model="filtroAnalise.labelFiltroOrdenacao"
                    hide-details
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="filtroAnalise.ordemCrescente"
                    :label="'Crescente'"
                  ></v-checkbox
                ></v-col>
                <v-col cols="8" v-if="filtroAnalise.labelFiltroOrdenacao">
                  <v-text-field
                    outlined
                    type="number"
                    dense
                    readonly
                    label="Nº de resultados"
                    v-model="filtroAnalise.quantidadeResultados"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" v-if="filtroAnalise.labelFiltroOrdenacao">
                  <v-row class="ma-0 pa-0">
                    <v-btn
                      @click="
                        filtroAnalise.quantidadeResultados =
                          parseInt(filtroAnalise.quantidadeResultados) + 1
                      "
                      x-small
                      icon
                      color="primary"
                    >
                      <v-icon>mdi-menu-up</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row class="ma-0 pa-0">
                    <v-btn
                      @click="
                        filtroAnalise.quantidadeResultados =
                          filtroAnalise.quantidadeResultados == 1
                            ? filtroAnalise.quantidadeResultados
                            : parseInt(filtroAnalise.quantidadeResultados) - 1
                      "
                      x-small
                      icon
                      color="primary"
                    >
                      <v-icon>mdi-menu-down</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="filtroAnalise.esconderPercentuais"
                    label="Ocultar Percentuais (%)"
                  ></v-checkbox>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="filtroAnalise.esconderTotaisColuna"
                    :label="'Ocultar Totais da Coluna'"
                  ></v-checkbox>
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="filtroAnalise.esconderTotaisLinha"
                    :label="'Ocultar Totais da Linha'"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0 pt-2" align="start" justify="end">
                <v-btn
                  large
                  block
                  :loading="isLoadingButton"
                  color="primary"
                  @click="consolidarFiltros"
                >
                  <span>Filtrar Análise</span>
                </v-btn>
              </v-row>
            </v-list>
            <v-card-actions> </v-card-actions>
          </v-card>
        </v-menu>
      </v-row>
    </v-col>
    <comentarios
      v-if="!!idAnalise && !dividirTela && !isInIframe"
      :anotacoes="filtroAnalise.anotacoes"
      :notas="filtroAnalise.notas"
      :identificadorAnalise="this.idAnalise"
      @changed="
        ({ anotacoes, notas }) => {
          filtroAnalise.anotacoes = anotacoes;
          filtroAnalise.notas = notas;
        }
      "
    ></comentarios>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import mixin from "@/mixin/index.js";
import parametrosTabelaAnalises from "@/constants/parametrosTabelaAnalises.constants.js";
import Anotacoes from "../components/Anotacoes.vue";
import AI from "../components/AI.vue";
import Compartilhar from "../components/Compartilhar.vue";
export default {
  name: "FiltrosAnalises",

  components: {
    comentarios: Anotacoes,
    compartilhar: Compartilhar,
    "lunis-ai": AI,
  },

  computed: {
    ...mapGetters("autenticacao", ["claimsUsuarioLogado"]),
    ...mapGetters(["isLoadingButton", "areasPerfis"]),
    ...mapGetters("analises", [
      "itensDistintos",
      "periodos",
      "analisesPorAreas",
      "filtro",
      "apresentacao",
    ]),
    isInIframe() {
      return window.self !== window.top;
    },
    dividirTela() {
      return !!this.apresentacao && !!this.apresentacao.dividirTela;
    },
    filtroAnalise: {
      get() {
        return this.filtro;
      },
      set(val) {
        this.setFiltro(val);
      },
    },
    filtrosPeriodoCampo: {
      get() {
        return this.periodos;
      },
      set(periodos) {
        this.setPeriodos(periodos);
      },
    },
    analises() {
      if (!this.area) return [];
      return this.analisesPorAreas.find((f) => {
        return f.id === this.area.id;
      }).analises;
    },
    agrupadores() {
      if (this.filtroAnalise.mapaColunas) {
        return parametrosTabelaAnalises.obterAgrupadores().filter((a) => {
          return !!Object.keys(this.filtroAnalise.mapaColunas).includes(a.nome);
        });
      }
      return parametrosTabelaAnalises.obterAgrupadores();
    },
  },
  props: {
    modulo: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapMutations("analises", [
      "setPeriodos",
      "setDadosGrafico",
      "setDadosTabela",
      "setFiltro",
      "setApresentacao",
    ]),
    ...mapActions("analises", [
      "obterDadosAnalisePorId",
      "obterListaDeAnalises",
    ]),
    ...mapActions(["showError", "showSuccess"]),

    obterDadosAnalisePorIdAsync: async (
      ctx,
      agrupador = null,
      consultarPeriodo = false
    ) => {
      if (!!ctx.idAnalise) {
        const analise = await ctx.obterDadosAnalisePorId({
          idAnalise: ctx.idAnalise,
          modulo: ctx.modulo,
          consultarPeriodo,
          agrupador,
        });

        if (!analise) {
          ctx.idAnalise = null;
          return;
        }
        
        ctx.filtroAnalise.anotacoes = analise.anotacoes || null;
        ctx.filtroAnalise.notas = analise.notas || null;
        ctx.filtroAnalise.filtrarEntrada = analise.filtrarEntrada;
        ctx.filtroAnalise.filtrarSaida = analise.filtrarSaida;
        ctx.filtroAnalise.esconderPercentuais = analise.esconderPercentuais;
        ctx.filtroAnalise.esconderTotaisColuna = analise.esconderTotaisColuna;
        ctx.filtroAnalise.esconderTotaisLinha = analise.esconderTotaisLinha;
        ctx.filtroAnalise.unidade = analise.unidade;
        ctx.filtroAnalise.filtrarQuitado = analise.filtrarQuitado;
        ctx.filtroAnalise.filtrarPendente = analise.filtrarPendente;
        ctx.filtroAnalise.quantidadeResultados = analise.quantidadeResultados;
        ctx.filtroAnalise.qtdCasasDecimais = analise.qtdCasasDecimais;
        ctx.filtroAnalise.ordemCrescente = analise.ordemCrescente;
        ctx.filtroAnalise.labelFiltroOrdenacao = analise.labelFiltroOrdenacao;
        ctx.filtroAnalise.itensDistintosSelecionados = analise.valoresAgrupador;
        ctx.filtroAnalise.mapaColunas = analise.mapaColunas || null;
        if (!consultarPeriodo) {
          ctx.triggerPeriodo = analise.periodos;
          ctx.filtroAnalise.agrupador = analise.agrupador;
        }
        ctx.triggerItensSelecionados = analise.valoresAgrupador;
        ctx.mostrarSeletorVarAnalisada = true;
        ctx.tipoAnaliseSelecionada = analise.tipo
        ctx.obterLabelsOrdenacao();
      }
    },

    async consolidarFiltros() {
      this.modelFiltrosGenericos = false;
      const naoPossuiItensDistintos =
        this.filtroAnalise.itensDistintosSelecionados == undefined ||
        this.filtroAnalise.itensDistintosSelecionados == null ||
        this.filtroAnalise.itensDistintosSelecionados.length == 0;
      if (naoPossuiItensDistintos) {
        this.filtroAnalise.itensDistintosSelecionados = this.itensDistintos;
      }
      this.filtroAnalise["nome"] = this.analisesPorAreas[0].analises.find(
        (f) => {
          return f.id === this.idAnalise;
        }
      )?.nome;
      this.filtroAnalise["labelFiltroOrdenacao"] = `Total (${
        this.filtroAnalise.unidade || "R$"
      })`;
      
      const acao = (this.tipoAnaliseSelecionada == 1 ? 'consolidarAnaliseHorizontal' : this.tipoAnaliseSelecionada == 2 ? 'consolidarAnalisePareto' : '');
      
      await this.$store.dispatch(
        `${this.modulo}/${acao}`,
        this.filtroAnalise,
        {
          root: true,
        }
      );
    },

    async filtrarPeriodo() {
      this.mostrarSeletorVarAnalisada = false;
      if (this.condicoesValidasParaFazerAnalise()) {
        await this.obterDadosAnalisePorIdAsync(
          this,
          this.filtroAnalise.agrupador,
          true
        );
      }
    },

    obterLabelsOrdenacao() {
      let filtroAnalise = this.filtrosPeriodoCampo;
      this.labelsFiltro = [`Total (${this.filtroAnalise.unidade || "R$"})`];

      const labelsOrdenacaoPorPeriodo = {
        1: () => {
          for (
            let diaRef = new Date(filtroAnalise.dataInicio);
            diaRef <= new Date(filtroAnalise.dataFim);
            diaRef.setDate(diaRef.getDate() + 1)
          ) {
            this.labelsFiltro.push(
              mixin.methods._obterDataFormatoBrasilDeUtcString(diaRef) +
                ` (${this.filtroAnalise.unidade || "R$"})`
            );
          }
        },
        2: () => {
          filtroAnalise.meses?.forEach((d) => {
            this.labelsFiltro.push(
              `${d.mes}-${d.ano} (${this.filtroAnalise.unidade || "R$"})`
            );
          });
        },
        3: () => {
          filtroAnalise.anos?.forEach((ano) => {
            this.labelsFiltro.push(
              `${ano} (${this.filtroAnalise.unidade || "R$"})`
            );
          });
        },
        4: () => {},
      };
      labelsOrdenacaoPorPeriodo[filtroAnalise?.tipoPeriodo]();
    },

    condicoesValidasParaFazerAnalise() {
      let filtroAnalise = this.filtrosPeriodoCampo;
      if (!this.idAnalise) {
        this.showError("Informe uma análise válida para fazer a consulta.");
        return false;
      }
      if (!filtroAnalise.tipoPeriodo) {
        this.showError("Informe um período válido para fazer a consulta.");
        return false;
      }
      if (filtroAnalise.tipoPeriodo == 1) {
        if (!filtroAnalise.dataInicio || !filtroAnalise.dataFim) {
          this.showError("Necessário informar Data Início e Data Fim!");
          return false;
        } else if (
          Math.ceil(
            (new Date(filtroAnalise.dataFim) -
              new Date(filtroAnalise.dataInicio)) /
              (1000 * 60 * 60 * 24)
          ) < 0
        ) {
          this.showError("A Data Início deve ser maior que a Data Fim!");
          return false;
        }
      } else if (filtroAnalise.tipoPeriodo == 2) {
        if (!filtroAnalise.meses.length || filtroAnalise.meses.length <= 0) {
          this.showError("Necessário informar pelo menos um mês.");
          return false;
        }
      } else if (filtroAnalise.tipoPeriodo == 3) {
        if (!filtroAnalise.anos.length || filtroAnalise.anos.length <= 0) {
          this.showError("Necessário informar pelo menos um ano.");
          return false;
        }
      }

      return true;
    },
  },
  data() {
    return {
      tipoAnaliseSelecionada: null,
      area: null,
      labelsFiltro: [],
      triggerPeriodo: null,
      mostrarSeletorVarAnalisada: false,
      triggerItensSelecionados: null,
      modelVariavel: false,
      modelFiltrosGenericos: false,
      seletorAnaliseModel: false,
      idAnalise: null,
      perfilUsuario: null,
      papelUsuario: null,
    };
  },

  watch: {
    tipoAnaliseSelecionada(val) {
      this.consolidarFiltros();
    },
    idAnalise(val) {
      if (!!val) {
        this.seletorAnaliseModel = false;
        this.obterDadosAnalisePorIdAsync(this);
        if (this.$route.query["id"] != val) {
          this.$router.replace({ query: { id: val } });
        }
      }
    },
    "$route.query.id": {
      handler: function (val) {
        if (this.idAnalise !== val) {
          this.idAnalise = val;
        }
      },
      immediate: false,
    },
    "filtroAnalise.agrupador"() {
      this.filtroAnalise = {
        ...this.filtroAnalise,
        itensDistintosSelecionados: [],
        itensDistintos: [],
      };
    },
    area(val) {
      if (val) {
        this.idAnalise = null;
      }
    },
    "$route.name": {
      handler: function (val) {
        this.idAnalise = null;
        this.setDadosTabela({});
        this.setDadosGrafico(null);
      },
      immediate: true,
    },
  },

  mounted: async function () {
    this.perfilUsuario = this.claimsUsuarioLogado?.perfil?.id;
    this.papelUsuario = this.claimsUsuarioLogado?.papel;

    await this.obterListaDeAnalises();
    this.area = this.analisesPorAreas[0];
    if (this.$route.params.idApresentacao) {
      let apresentacao = this.analisesPorAreas[0].analises.find(
        (t) => t.id == this.$route.params.idApresentacao
      );
      if (apresentacao) {
        this.setApresentacao(apresentacao);
      }
    }

    this.$nextTick(() => {
      if (this.$route.query["id"]) {
        this.idAnalise = this.$route.query["id"];
      }
    });
  },
};
</script>
