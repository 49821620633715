<template>
  <v-row
    v-if="naoPossuiResultados"
    justify="center"
    class="ma-0 pa-0 mt-5 pt-5"
  >
    <v-col class="ma-0 pa-0" cols="12">
      <v-row class="ma-0 pa-0" justify="center">
        <v-img
          position="center"
          height="200px"
          class="ma-0 pa-0"
          :src="semResultados"
          :lazy-src="semResultados"
          max-width="300"
          contain
        ></v-img>
      </v-row>
    </v-col>
    <v-col class="ma-0 pa-0 px-5" cols="12">
      <p class="card-title-disabled text-center ma-0 pa-0 mt-5">
        Sem resultados
      </p>
      <p class="card-text-disabled text-center ma-0 pa-0 mt-1">
        Selecione filtros válidos e os dados da sua consulta vão aparecer aqui
      </p>
    </v-col>
  </v-row>
</template>


<script>
export default {
  computed: {
    naoPossuiResultados() {
      return (
        Object.keys(this.$store.getters["analises/dadosTabela"]).length === 0
      );
    },
  },
  data() {
    return {
      semResultados: require("../../public/sem-resultados.png"),
    };
  },
};
</script>