import services from "@/services/services.js";

export default {

    async salvarEdicaoCriacaoAnalise(body) {
        return await services.postAsync(`/cargas/analises/`, body);
    },

    async salvarApresentacao(body) {
        return await services.postAsync(`/cargas/analises/apresentacoes`, body);
    },

    async salvarArquivo({ arquivo, idAnalise }) {
        return await services.postAsync(`/cargas/analises/${idAnalise}/arquivos/`, { arquivo });
    },

    async deletarAnalise(idAnalise) {
        return await services.deleteAsync(`/cargas/analises/${idAnalise}`);
    },

    async salvarAnotacoesAnalise({ id, anotacoes, notas }) {
        return await services.postAsync(`/cargas/analises/${id}/anotacoes/`, { anotacoes, notas });
    },
}