import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import brain from './brain.vue';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#125e55',
                secondary: '#3E4656',
                'background': '#F4F5FA',
            },
        }
    },
    icons: {
        iconfont: 'mdiSvg',
        values: {
            brain: { component: brain },
        }
    }
});
