<template>
  <v-row class="app-content ma-0 pr-4" align="start" justify="start">
    <v-col cols="4" class="ma-0 pa-0 mb-5">
      <v-autocomplete 
        label="Selecione"
        prepend-icon="mdi-search-web"
        v-model="analiseModel"
        :value="analiseModel"
        item-text="nome"
        return-object
        :items="analisesExternas"
        hide-details
        hide-selected
      ></v-autocomplete>
    </v-col>
    <v-col cols="8" class="ma-0 pa-0 mb-5">
      <v-row class="ma-0 pa-0 px-4" justify="end" align="center">
        <compartilhar></compartilhar>
      </v-row>
    </v-col>
    <v-spacer></v-spacer> 
    <v-col v-if="visualizacaoPermitida" cols="12" class="ma-0 pa-0">
      <iframe 
        width="100%"
        :height="`${tamanhoRowGrafico}px`"
        :src="analiseModel.src" 
        frameborder="0" 
        allowFullScreen="true"
      >
      </iframe>
    </v-col>
    <v-col v-if="!visualizacaoPermitida" :cols="12" class="ma-0 pa-10">
      <v-card-text>
          <v-row justify="center">
            <v-icon size="64" color="secondary">mdi-alert-circle-outline</v-icon>
          </v-row>
          <v-row justify="center">
            <p class="text-uppercase font-weight-regular" style="color: rgb(101, 101, 101); size: 64;">Desculpe! Seu usuário não possui permissão para visualizar essa análise.</p>
          </v-row>
        </v-card-text>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Compartilhar from "../components/Compartilhar.vue";

export default {
  components: { compartilhar: Compartilhar },
  name: "AnalisesExternas",

  computed: {
    ...mapGetters("autenticacao", ["claimsUsuarioLogado"]),
    ...mapGetters("analises", [
      "analisesExternas",
      "analiseExternaSelecionada",
    ]),
    analiseModel: {
      get() {
        return this.analiseExternaSelecionada;
      },
      set(analiseSelecionada) {
        if ( this.papelUsuario !== 1 && !!analiseSelecionada.perfis && (!analiseSelecionada.perfis.includes(this.perfilUsuario))) {
          this.visualizacaoPermitida = false;
        } else {
          this.visualizacaoPermitida = true;
        }

        this.setAnaliseExternaSelecionada(analiseSelecionada);
      },
    },
  },

  methods: {
    ...mapActions("analises", ["obterListaDeAnalises"]),
    ...mapMutations("analises", ["setAnaliseExternaSelecionada"]),
  },

  watch: {
    indiceAnaliseExterna(val) {
      if (
        !this.analiseModel ||
        this.analiseModel.src !== this.analisesExternas[val].src
      ) {
        this.analiseModel = this.analisesExternas[val];
      }
    },
    analiseModel(val) {
      this.analisesExternas.forEach((analise, indice) => {
        if (
          analise.src === val.src &&
          this.$route.query["indice"] !== `${indice}`
        ) {
          this.$router.replace({ query: { indice } });
        }
      });
    },
  },

  async mounted() {
    if (!this.analisesExternas || !this.analisesExternas.length) {
      await this.obterListaDeAnalises();
    }
    this.$nextTick(() => {
      if (!!this.$route.query["indice"]) {
        this.indiceAnaliseExterna = parseInt(this.$route.query["indice"]);
      } else if (!this.analiseModel) {
        this.analiseModel = this.analisesExternas[0];
      } else {
      }
    });

    this.perfilUsuario = this.claimsUsuarioLogado?.perfil?.id;
    this.papelUsuario = this.claimsUsuarioLogado?.papel;
  },

  data() {
    return {
      tamanhoRowGrafico: window.innerHeight -140,
      zoomLevel: 1,
      report: null,
      indiceAnaliseExterna: null,
      perfilUsuario: null,
      papelUsuario: null,
      visualizacaoPermitida: false,
    };
  },
};
</script>
