<template>
  <v-row
    class="primary pa-0 ma-0"
    align="start"
    justify="center"
    style="height: 100% !important"
  >
    <v-col cols="6" no-gutters style="height: 100% !important">
      <v-row
        no-gutter
        justify="center"
        align-content="center"
        class=""
        style="height: 100% !important"
      >
        <v-col class="ma-0 pa-0" cols="12">
          <v-img
            contain
            height="150px"
            :src="require('../../assets/lunis-logo.svg')"
          ></v-img>
        </v-col>
        <v-col class="ma-0 pa-0" cols="12">
          <v-img
            contain
            class="mt-16"
            height="140px"
            :src="require('../../assets/lunis-apresentacao.svg')"
          ></v-img>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6" class="px-3" no-gutters style="height: 100% !important">
      <v-row
        no-gutter
        justify="center"
        align="center"
        style="height: 100% !important"
      >
        <v-card
          class="auth-card pa-12 py-10"
          max-width="500"
          height="70%"
          style="border-radius: 36px"
        >
          <h5 class="text-h5 mb-1">Decisões baseadas em dados</h5>
          <p class="mb-0">Faça seu login abaixo para começar.</p>

          <v-card-text class="mt-5">
            <v-text-field
              v-model="usuario.email"
              prepend-inner-icon="mdi-email"
              label="Email"
              type="text"
              color="primary"
              @keyup.enter="validarCredenciais()"
            ></v-text-field>
            <v-text-field
              prepend-inner-icon="mdi-lock-outline"
              v-model="usuario.senha"
              label="Senha"
              type="password"
              color="primary"
              @keyup.enter="validarCredenciais()"
            ></v-text-field>
            <v-row no-gutters justify="center">
              <v-btn
                :loading="isLoadingButton"
                @click="validarCredenciais()"
                center
                rounded
                large
                class="mt-6 px-16"
                color="primary"
                dark
                >ENTRAR</v-btn
              >
            </v-row>

            <v-dialog v-model="dialogRecuperarSenha" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-row no-gutters justify="center">
                  <v-btn
                    text
                    class="text-decoration-underline mt-5 caption"
                    v-bind="attrs"
                    v-on="on"
                    color="secondary"
                    small
                  >
                    Alterar Senha
                  </v-btn>
                </v-row>
              </template>
              <v-card class="pa-6">
                <v-card-title>
                  <span class="text-h5">Alterar Senha</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="ma-0 pa-0">
                        <v-form v-model="formValido" ref="formularioLogin">
                          <v-text-field
                            v-model="emailRecuperar"
                            label="Email *"
                            :rules="regrasEmail"
                          ></v-text-field>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="fecharModalRecuperarSenha"
                    class="primary--text"
                  >
                    CANCELAR
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="ml-5"
                    @click="recuperarSenha"
                    :loading="isLoadingButton"
                    :disabled="!formValido"
                    >Solicitar Alteração</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-row no-gutters justify="center" class="mt-5 pb-5">
              <v-btn
                @click="signInWithGoogle()"
                :disabled="isLoadingButton"
                center
                rounded
                large
                class="mt-6 px-6"
                color="secondary"
                dark
              >
                <v-icon class="mr-4">mdi-google</v-icon>

                ENTRAR com o GOOGLE</v-btn
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<style>
.auth-card {
  z-index: 1 !important;
}
</style>

<script>
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "Login",
  components: {},

  data() {
    return {
      usuario: {
        email: null,
        senha: null,
      },
      dialogRecuperarSenha: false,
      formValido: true,
      emailRecuperar: null,
      regrasEmail: [
        (v) => !!v || "Obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
      ],
    };
  },

  computed: {
    ...mapGetters(["isLoadingButton"]),
  },

  methods: {
    ...mapActions(["redirect", "showError"]),
    ...mapActions("autenticacao", [
      "solicitarEmailTrocaSenha",
      "verificarOrganizacaoAcesso",
    ]),
    ...mapMutations(["setLoadingButton", "setEmail", "setTokenUsuario"]),
    ...mapMutations("autenticacao", ["setClaimsUsuarioLogado"]),
    async signInWithGoogle() {
      this.setLoadingButton(true);
      await setPersistence(getAuth(), browserLocalPersistence);
      try {
        let provider = new GoogleAuthProvider();
        await signInWithPopup(getAuth(), provider);
        const { claims } = await getAuth().currentUser.getIdTokenResult(true);
        this.setClaimsUsuarioLogado(claims);
        await this.verificarOrganizacaoAcesso({ claims });
        const token = await getAuth().currentUser.getIdToken(true);
        this.setTokenUsuario(token);
        this.setEmail(this.usuario.email);
        this.setLoadingButton(false);
        if (!!window.sessionStorage.getItem("lunis-next-route")) {
          const route = window.sessionStorage.getItem("lunis-next-route");
          if (route.name !== "login") {
            return this.$router.replace({ path: route });
          }
        }
        this.redirect("home");
      } catch {
        this.showError(
          "Ocorreu um erro com o seu acesso, verifique suas credenciais ou permissões de acesso."
        );
        this.setLoadingButton(false);
      }
    },
    async validarCredenciais() {
      this.setLoadingButton(true);
      await setPersistence(getAuth(), browserLocalPersistence);
      try {
        await signInWithEmailAndPassword(
          getAuth(),
          this.usuario.email,
          this.usuario.senha
        );
        const { claims } = await getAuth().currentUser.getIdTokenResult(true);
        this.setClaimsUsuarioLogado(claims);
        await this.verificarOrganizacaoAcesso({ claims });
        const token = await getAuth().currentUser.getIdToken(true);
        this.setTokenUsuario(token);
        this.setEmail(this.usuario.email);
        this.setLoadingButton(false);
        if (!!window.sessionStorage.getItem("lunis-next-route")) {
          const route = window.sessionStorage.getItem("lunis-next-route");
          if (route.name !== "login") {
            return this.$router.replace({ path: route });
          }
        }
        this.redirect("home");
      } catch (err) {
        this.showError(
          "Seu acesso não foi autorizado, verifique suas credenciais ou permissões de acesso."
        );
        this.setLoadingButton(false);
      }
    },

    fecharModalRecuperarSenha() {
      this.dialogRecuperarSenha = false;
      this.emailRecuperar = null;
    },

    async recuperarSenha() {
      await this.solicitarEmailTrocaSenha(this.emailRecuperar);
      this.fecharModalRecuperarSenha();
    },
  },
};
</script>
