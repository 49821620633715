<template>
  <v-overlay :value="isLoading" style="z-index:100">
    <v-progress-circular :size="70" :width="4" color="primary" indeterminate></v-progress-circular>
  </v-overlay>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isLoading"])
  },
  components: {}
};
</script>