export default {
    apresentacao: null,
    filtro: {
        filtrarEntrada: true,
        filtrarSaida: true,
        filtrarQuitado: true,
        filtrarPendente: true,
        itensDistintosSelecionados: [],
        itensDistintos: [],
        agrupador: "",
        mapaColunas: null,
        ordemCrescente: true,
        labelFiltroOrdenacao: "",
        quantidadeResultados: 10,
        esconderPercentuais: false,
        esconderTotaisColuna: false,
        esconderTotaisLinha: false,
        anotacoes: "",
        tipoAnalise: null,
    },
    dadosTabela: {},
    dadosGrafico: null,
    dadosGraficoTotalizadores: null,
    periodos: {},
    lancamentos: {},
    itensDistintos: [],
    analisesPorAreas: [],
    dataUltimaAtualizacaoAnalise: null,
    analiseExternaSelecionada: null,
    analisesExternas: [],
    feed: {
        selecionado: null,
        lista: []
    },
    semPermissaoVisualizacaoAnalise: false,
    erroVisualizacaoAnalise: false,
}