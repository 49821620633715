import services from "@/services/services.js";

export default {
    async consultarUsuarios() {
        return await services.getAsync(`/autenticacao/usuarios/`);
    },
    async criarNovoUsuario(body) {
        return await services.postAsync(`/autenticacao/usuarios/`, body);
    },
    async obterClaimsUsuarioSelecionado(uid) {
        return await services.getAsync(`/autenticacao/claims/${uid}`);
    },
    async atualizarClaimsUsuario(uid, body) {
        return await services.postAsync(`/autenticacao/claims/${uid}`, body);
    },
    async deletarUsuario(uid) {
        return await services.deleteAsync(`/autenticacao/usuarios/${uid}`);
    },
}