<template>
  <v-row class="app-content ma-0 pr-8" justify="start">
    <v-col v-if="abaHome == 0" cols="12" class="ma-0 pa-0">
      <v-card>
        <v-row class="ma-0 pa-2 pb-4 mb-5" align="center">
          <v-col cols="4" class="ma-0 pa-0">
            <v-text-field
              outlined
              dense
              v-model="search"
              hide-details
              label="Pesquisar"
              prepend-icon="mdi-magnify"
            ></v-text-field>
          </v-col>
          <v-col class="ma-0 ml-6">
            <v-autocomplete
              label="Áreas"
              clearablechipslabel="Autocomplete"
              v-model="areasSelecionadas"
              :items="areas"
              item-text="nome"
              item-value="id"
              multiplevariant="underlined"
              multiple
              chips
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              label="Tipos"
              clearablechipslabel="Autocomplete"
              v-model="tiposSelecionados"
              :items="tipos"
              item-text="nome"
              item-value="id"
              multiplevariant="underlined"
              multiple
              chips
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col class="ma-0 pa-0">
            <v-menu
              v-model="ordenacaoMenuModel"
              :close-on-content-click="false"
              :nudge-width="200"
            >
              <template v-slot:activator="{ on: onMenu }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-row class="ma-0 pa-0" justify="end">
                      <v-btn
                        v-on="{ ...onMenu, ...onTooltip }"
                        icon
                        color="primary"
                      >
                        <v-icon>mdi-swap-vertical</v-icon>
                      </v-btn>
                    </v-row>
                  </template>
                  <span>Classificar</span>
                </v-tooltip>
              </template>

              <v-card class="pb-5">
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Classificar</v-list-item-title>
                      <v-list-item-subtitle>
                        Selecione a ordenação desejada
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar>
                      <v-icon color="primary">mdi-swap-vertical</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-switch
                  v-model="sortDesc"
                  :label="sortDesc ? 'Decrescente' : 'Crescente'"
                  class="pa-4"
                ></v-switch>
                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item-group
                    v-model="listaItensOrdenacaoModel"
                    color="primary"
                  >
                    <v-list-item v-for="(item, i) in listaOrdenacao" :key="i">
                      <v-list-item-icon>
                        <v-icon v-text="item.icone"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.nome"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    outlined
                    class="ma-0 mx-2 pa-2 mt-3"
                    @click="ordenacaoMenuModel = false"
                  >
                    Fechar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" v-if="abaHome == 0" class="ma-0 pa-0 pb-4">
      <v-data-table
        hide-default-footer
        :headers="cabecalhos"
        :items="todasAsAnalises"
        :items-per-page="100"
        class="elevation-1"
        :search="search"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :height="tamanhoTabela"
        fixed-header
      >
        <template v-slot:[`item.nome`]="{ item }">
          <a
            :href="montarLink(item)"
            @click="logarClick(item, 'resumo')"
            class="ma-0 pa-0 lunis-cursor"
          >
            <span class="text-decoration-underline blue--text text--darken-4">
              {{ item.nome }}
            </span>
          </a>
        </template>
        <template v-slot:[`item.tipoPeriodo`]="{ item }">
          <span v-if="item.tipoPeriodo">
            {{
              { 1: "Diário", 2: "Mensal", 3: "Anual", 4: "Indefinido" }[
                item.tipoPeriodo
              ] || "Análise Externa"
            }}
          </span>
        </template>
        <template v-slot:[`item.dataAtualizacaoBase`]="{ item }">
          <span v-if="item.dataAtualizacaoBase">
            {{
              _obterDataFormatoBrasilDeUtcString(item.dataAtualizacaoBase, true)
            }}
          </span>
        </template>

        <template v-slot:[`item.areas`]="{ item }">
          <v-chip
            outlined
            color="primary"
            class="my-1 mr-1 ma-0"
            dark
            v-for="area in getItemAreas(item)"
            :key="`areas${area}`"
          >
            {{ area }}
          </v-chip>
        </template>
        <template v-slot:[`item.tipo`]="{ item }">
          <v-chip outlined color="primary" dark v-if="!!item.tipo">
            {{
              {
                1: "Horizontais",
                2: "Pareto",
                3: "Power BI",
                4: "Data Studio",
                5: "Planilha",
                6: "Apresentação",
              }[item.tipo]
            }}
          </v-chip>
          <v-chip outlined color="primary" dark v-else> Horizontais </v-chip>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="abaHome == 1" cols="12" class="ma-0 pa-0">
      <v-row class="ma-0 pa-0 my-5 pb-5" justify="center">
        <v-btn
          v-if="
            !!socialFeed.selecionado &&
            !!socialFeed.lista.length &&
            !!socialFeed.selecionado.indice
          "
          @click="selecionarItemFeed(socialFeed.selecionado.indice - 1)"
          color="primary"
          fab
          x-small
          dark
        >
          <v-icon>mdi-arrow-up-bold</v-icon>
        </v-btn>
      </v-row>
    </v-col>
    <v-col v-if="abaHome == 1" cols="12" class="ma-0 pa-0">
      <v-row
        v-if="!!socialFeed.selecionado && !!socialFeed.lista.length"
        justify="end"
        class="ma-0 pa-0 px-5"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :href="montarLink(socialFeed.selecionado)"
              v-bind="attrs"
              v-on="on"
              @click="logarClick(socialFeed.selecionado, 'feed')"
            >
              <v-icon color="primary">mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <span>Expandir análise</span>
        </v-tooltip>
      </v-row>
      <grafico-misto v-bind:dadosGrafico="dadosGrafico"></grafico-misto>
    </v-col>
    <v-col v-if="abaHome == 1" cols="12" class="ma-0 pa-0">
      <v-row class="ma-0 pa-0 my-5 pt-5" justify="center">
        <v-btn
          v-if="
            !!socialFeed.selecionado &&
            !!socialFeed.lista.length &&
            socialFeed.lista.length - 1 > socialFeed.selecionado.indice
          "
          @click="selecionarItemFeed(socialFeed.selecionado.indice + 1)"
          color="primary"
          fab
          dark
          x-small
        >
          <v-icon>mdi-arrow-down-bold</v-icon>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<style>
.lunis-cursor {
  cursor: pointer;
}
</style>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import useAnalytics from "../services/analytics.service";

const { logarEvento } = useAnalytics();

export default {
  name: "Home",
  components: {},
  computed: {
    ...mapGetters("analises", ["analisesPorAreas", "analisesExternas", "feed"]),
    ...mapGetters("autenticacao", ["areasPerfis"]),
    ...mapGetters(["areasPerfis", "abaHome"]),
    todasAsAnalises() {
      let arr = [];
      if (!!this.analisesPorAreas.length) {
        arr = arr.concat(this.analisesPorAreas[0].analises);
      }
      if (!!this.analisesExternas && !!this.analisesExternas.length) {
        arr = arr.concat(this.analisesExternas);
      }
      if (!!this.areasSelecionadas.length) {
        arr = arr.filter((analise) =>
          analise.areas?.some((area) => this.areasSelecionadas.includes(area))
        );
      }
      if (!!this.tiposSelecionados.length) {
        arr = arr.filter((analise) =>
          this.tiposSelecionados.includes(analise.tipo)
        );
      }
      return arr;
    },
    areas: {
      get() {
        return this.areasPerfis?.areas;
      },
    },
    socialFeed: {
      get() {
        return this.feed;
      },
      set(val) {
        this.setFeed(val);
      },
    },
  },
  methods: {
    ...mapActions("analises", [
      "obterGraficoAnaliseDeTabela",
      "obterAnalisePorId",
    ]),
    ...mapActions("autenticacao", ["exibirListaOrganizacoes"]),
    ...mapMutations("analises", [
      "setAnaliseExternaSelecionada",
      "setFeed",
      "setApresentacao",
    ]),
    ...mapMutations(["setAbaHome"]),
    logarClick(item, pagina) {
      logarEvento("click_event", { pagina: pagina, ...item });
    },
    montarLink(analise) {
      if ([6].includes(analise.tipo)) {
        return `/analises/apresentacoes/${analise.id}`;
      } else if ([1, 2].includes(analise.tipo) || !analise.tipo) {
        return `/analises?id=${analise.id}`;
      } else {
        let indiceAnaliseExterna;
        this.analisesExternas.forEach((analiseExterna, indice) => {
          if (analise.src === analiseExterna.src) {
            indiceAnaliseExterna = indice;
          }
        });
        return `/analises/externas?indice=${indiceAnaliseExterna}`;
      }
    },
    getItemAreas(item) {
      let arr = [];
      if (!!item.areas) {
        item.areas.forEach((area) => {
          arr.push(
            this.areasPerfis.areas.find((a) => {
              return a.id == area;
            }).nome
          );
        });
      }
      return arr.sort();
    },
    obterListaFeed: async function () {
      if (!this.socialFeed.lista.length) {
        this.socialFeed.lista = this.todasAsAnalises
          .filter((analise) => {
            return [1, 2].includes(analise.tipo || 1) && !!analise.consolidada;
          })
          .sort((a, b) => b.dataAtualizacaoBase - a.dataAtualizacaoBase);
      }
      if (!this.socialFeed.selecionado && !!this.socialFeed.lista.length) {
        await this.selecionarItemFeed();
      } else if (
        !!this.socialFeed.selecionado &&
        !!this.socialFeed.selecionado.tabelaConsolidada
      ) {
        this.$nextTick(async () => {
          this.dadosGrafico = await this.obterGraficoAnaliseDeTabela({
            analise: this.socialFeed.selecionado,
          });
        });
      }
    },
    selecionarItemFeed: async function (indice = 0) {
      let analise = this.socialFeed.lista[indice];
      if (!analise.tabelaConsolidada) {
        analise = await this.obterAnalisePorId({
          idAnalise: analise.id,
        });
      }
      this.socialFeed.selecionado = { ...analise, indice };
      this.socialFeed.lista[indice] = { ...analise };
      this.$nextTick(async () => {
        this.dadosGrafico = await this.obterGraficoAnaliseDeTabela({ analise });
      });
    },
  },
  created: function () {
    this.setApresentacao(null);
    this.exibirListaOrganizacoes();
  },
  watch: {
    listaItensOrdenacaoModel(val) {
      if (typeof val !== "number") {
        this.sortBy = undefined;
        this.sortDesc = undefined;
        return;
      }
      if (typeof sortDesc !== "boolean") {
        this.sortDesc = false;
      }
      this.sortBy = this.listaOrdenacao[val].sortBy;
    },
    abaHome(val) {
      if (val == 1) {
        this.obterListaFeed();
      }
    },
    sortBy(val) {
      if (!val) {
        this.listaItensOrdenacaoModel = null;
        return;
      }
      this.listaOrdenacao.forEach((obj, index) => {
        if (obj.sortBy === val) {
          this.listaItensOrdenacaoModel = index;
        }
      });
    },
  },
  data() {
    return {
      dadosGrafico: null,
      tamanhoTabela: window.innerHeight - 294,
      search: "",
      sortBy: "nome",
      sortDesc: false,
      ordenacaoMenuModel: false,
      areasSelecionadas: [],
      tiposSelecionados: [],
      listaOrdenacao: [
        {
          id: 1,
          nome: "Nome",
          icone: "mdi-order-alphabetical-ascending",
          sortBy: "nome",
        },
        {
          id: 2,
          nome: "Tipo",
          icone: "mdi-finance",
          sortBy: "tipo",
        },
        {
          id: 3,
          nome: "Data de Atualização",
          icone: "mdi-calendar-sync-outline",
          sortBy: "dataAtualizacaoBase",
        },
        {
          id: 4,
          nome: "Período",
          icone: "mdi-calendar-multiselect-outline",
          sortBy: "tipoPeriodo",
        },
      ],
      listaItensOrdenacaoModel: 0,
      cabecalhos: [
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "nome",
        },
        { text: "Áreas", value: "areas", sortable: false },
        { text: "Período", value: "tipoPeriodo", sortable: true },
        {
          text: "Data de Atualização",
          value: "dataAtualizacaoBase",
          sortable: true,
        },
        { text: "Tipo", value: "tipo", sortable: true },
      ],
      tipos: [
        { id: 1, nome: "Horizontais" },
        { id: 2, nome: "Pareto" },
        { id: 3, nome: "Power BI" },
        { id: 4, nome: "Data Studio" },
        { id: 5, nome: "Planilha" },
        { id: 6, nome: "Apresentação" },
      ],
    };
  },
  mounted: function () {
    this.setAbaHome(0);
  },
};
</script>
