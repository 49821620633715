<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        @click="compartilhar()"
        class="ma-0 pa-0 mr-2"
        icon
        color="primary"
        v-on="on"
      >
        <v-icon color="primary">mdi-export-variant</v-icon>
      </v-btn>
    </template>
    <span>Compartilhar</span>
  </v-tooltip>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Compartilhar",
  methods: {
    ...mapActions(["compartilhar"]),
  },
};
</script>
