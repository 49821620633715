import axios from "axios";
import store from "../store";
import router from "@/router/index.js";
import config from "../../config.json";

const request = function () {
  return axios
    .create({
      baseURL: config.baseURL
    });
}

const handleErroComunicacaoBackend = function (error) {
  if (error.response.status === 401) {
    router.replace({ name: "login" });
    window.showError("Sessão Expirada! Favor realizar login novamente.");
  } else if (error.response.status === 400) {
    throw new Error(error.response.data.mensagem);
  } else {
    throw new Error(error.response.status);
  }
}

export default {
  getAsync: async function (route) {
    const response = await request().get(route, {
      headers: {
        'token': store.getters.tokenUsuario,
        'organizacao': window.localStorage.getItem('lunis-organizacao') || 'lunis'
      }
    }).catch(error => {
      handleErroComunicacaoBackend(error);
    });

    return response.data.payload;
  },
  postAsync: async function (route, body) {
    const response = request().post(route, body, {
      headers: {
        'token': store.getters.tokenUsuario,
        'organizacao': window.localStorage.getItem('lunis-organizacao') || 'lunis'
      }
    }).catch(error => {
      handleErroComunicacaoBackend(error);
    });

    return (await response).data.payload;
  },
  deleteAsync: async function (route) {
    const response = request().delete(route, {
      headers: {
        'token': store.getters.tokenUsuario,
        'organizacao': window.localStorage.getItem('lunis-organizacao') || 'lunis'
      }
    }).catch(error => {
      handleErroComunicacaoBackend(error);
    });

    return (await response).data.payload;
  },
};
