export default {
    apresentacao: state => state.apresentacao,
    filtro: state => state.filtro,
    dadosTabela: state => state.dadosTabela,
    dadosGrafico: state => state.dadosGrafico,
    dadosGraficoTotalizadores: state => state.dadosGraficoTotalizadores,
    periodos: state => state.periodos,
    lancamentos: state => state.lancamentos,
    itensDistintos: state => state.itensDistintos,
    analisesPorAreas: state => state.analisesPorAreas,
    dataUltimaAtualizacaoAnalise: state => state.dataUltimaAtualizacaoAnalise,
    analiseExternaSelecionada: state => state.analiseExternaSelecionada,
    analisesExternas: state => state.analisesExternas,
    feed: state => state.feed,
    semPermissaoVisualizacaoAnalise: state => state.semPermissaoVisualizacaoAnalise,
    erroVisualizacaoAnalise: state => state.erroVisualizacaoAnalise,
}