<template>
  <v-row
    :class="!isInIframe ? 'app-content ma-0 pr-4 pb-5' : 'ma-0 pa-0'"
    align="start"
    justify="center"
  >
    <v-col v-if="!apresentacao && !semPermissaoVisualizacaoAnalise & !erroVisualizacaoAnalise" :cols="12" class="ma-0 pa-2">
      <filtros modulo="analises"></filtros>
      <grafico-misto
        v-bind:dadosGrafico="
          verTotais ? dadosGraficoTotalizadores : dadosGrafico
        "
      ></grafico-misto>

      <v-row
        v-if="mostrarSeletorGraficoTotalizador"
        justify="center"
      >
        <v-switch v-model="verTotais" label="Ver totais"></v-switch>
      </v-row>

      <v-row
        v-if="dataUltimaAtualizacaoAnalise != null"
        justify="end"
        class="mr-1 mt-5"
      >
        <p>{{ `Último Processamento: ${dataUltimaAtualizacaoAnalise}` }}</p>
      </v-row>

      <tabela v-bind:dadosTabela="dadosTabela" :notas="filtro.notas"></tabela>
    </v-col>
    <v-col v-if="semPermissaoVisualizacaoAnalise" :cols="12" class="ma-0 pa-10">
      <v-card-text>
          <v-row justify="center">
            <v-icon size="64" color="secondary">mdi-alert-circle-outline</v-icon>
          </v-row>
          <v-row justify="center">
            <p class="text-uppercase font-weight-regular" style="color: rgb(101, 101, 101); size: 64;">Desculpe! Seu usuário não possui permissão para visualizar essa análise.</p>
          </v-row>
        </v-card-text>
    </v-col>
    <v-col v-if="erroVisualizacaoAnalise" :cols="12" class="ma-0 pa-10">
      <v-card-text>
          <v-row justify="center">
            <v-icon size="64" color="secondary">mdi-alert-outline</v-icon>
          </v-row>
          <v-row justify="center">
            <p class="text-uppercase font-weight-regular" style="color: rgb(101, 101, 101); size: 64;">Falha ao processar análise. Caso o erro persista, favor entrar em contato com o administrador do sistema.</p>
          </v-row>
        </v-card-text>
    </v-col>
    <Apresentacao v-if="!!apresentacao"></Apresentacao>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import FiltrosAnalises from "../components/FiltrosAnalises.vue";
import Apresentacao from "../components/Apresentacao.vue";

export default {
  components: { filtros: FiltrosAnalises, Apresentacao: Apresentacao },
  name: "AnalisesFinaceirasHorizontais",

  computed: {
    ...mapGetters("analises", [
      "dadosTabela",
      "dadosGrafico",
      "dadosGraficoTotalizadores",
      "dataUltimaAtualizacaoAnalise",
      "filtro",
      "apresentacao",
      "semPermissaoVisualizacaoAnalise",
      "erroVisualizacaoAnalise",
    ]),
    isInIframe() {
      return window.self !== window.top;
    },
    dividirTela() {
      return !!this.apresentacao && !!this.apresentacao.dividirTela;
    },
  },

  methods: {
    ...mapMutations("analises", [
      "setDadosTabela",
      "setDadosGrafico",
      "setDataUltimaAtualizacaoAnalise",
    ]),
  },

  created() {
    this.setDadosGrafico({});
    this.setDadosTabela({});
    this.setDataUltimaAtualizacaoAnalise(null);
  },
  watch: {
    dadosGraficoTotalizadores(val) {
      this.mostrarSeletorGraficoTotalizador = !!val;
    },
    "$route.name": {
      handler: function () {
        this.mostrarSeletorGraficoTotalizador = false;
      },
      immediate: true,
    },
    "$route.path": {
      handler: function () {
        this.verTotais = false;
      },
      immediate: true,
    },
  },

  data() {
    return { verTotais: false, mostrarSeletorGraficoTotalizador: false };
  },
};
</script>
