import { analytics } from "../main";

export default function useAnalytics() {
    function logarEvento(nomeEvento, parametros = {}) {
        analytics.logEvent(nomeEvento, parametros);
    }

    return { logarEvento }
}

